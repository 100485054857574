.dark-mode {
    --mentions-border-color: var(--primary-color);
    --mentions-background-color: var(--primary-color);
    background-color: initial;
}


.joynt-theme .dark-mode {
    --bg-color: var(--primary-text);
    --fg-color: white;
    --link-color: #F7B0CE; /** var(--success-color); **/
    --primary-light: rgba(255,255,255,0.1);
    --primary-lighter: rgba(255,255,255,0.05);
    --primary-lightest: rgba(255,255,255,0.025);
    /*--primary-color-rgb: 255,255,255;*/
    --scrollbar-color: rgba(255, 255, 255, 0.2);
    --component-bg: var(--primary-lightest);
    --theme-green: #5CC781;
    --theme-green-rgb: 92, 199, 129;
    --theme-red: #EA587A;
    --theme-red-rgb: 234, 88, 122;

    color: var(--fg-color);
}

.joynt-theme .dark-mode .MuiButton-contained,
.joynt-theme .dark-mode .MuiButton-contained.button--green {
    color: var(--fg-color);
}

.joynt-theme .dark-mode .MuiButton-containedPrimary {
    background-color: white;
    color: var(--text-color);
}

.joynt-theme .dark-mode .MuiButton-outlined {
    color: white;
}

.joynt-theme .dark-mode .MuiInputBase-root {
    color: white;
}

.joynt-theme .dark-mode .MuiButton-contained:hover {
    background-color: var(--success-color);
}

.joynt-theme .dark-mode .MuiButton-contained.Mui-disabled {
    background-color: rgba(var(--primary-color-rgb), 0.2);
    color: white;
}

.joynt-theme .dark-mode .MuiButton-contained.Mui-disabled  .MuiButton-label {
    opacity: 0.65;
}

.joynt-theme .dark-mode .MuiFormLabel-root.Mui-focused {
    color: var(--success-color);
}

.joynt-theme .dark-mode .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--success-color);
}

.joynt-theme .dark-mode .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--success-color);
}

.joynt-theme .dark-mode .MuiCheckbox-root {
    color: white;
}

.dark-mode.bg {
    background-color: var(--bg-color);
}

.view.dark-mode {
    background-color: var(--primary-dark-true);
}

.joynt-theme .dark-mode .ui-preloader {
    background-color: rgba(var(--primary-dark-rgb), 0.8);
}

.joynt-theme .dark-mode .ui-preloader .MuiCircularProgress-colorPrimary {
    color: white;
}

.MuiDialog-root.dark-mode .MuiDialog-paper {
    background-color: var(--bg-color);
}
.page {
    display: flex;
    flex-direction: column;
}

.editor-canvas {
    font-size: var(--font-size-paragraph);
    line-height: 1.6em;
    display: flex;
    flex-direction: column;
    flex: 1;

    --placholder-text: "Type '/' for commands";
    --mt: 0;
}

.editor-canvas.dev {
    padding: var(--size-4);
    border: solid 1px var(--primary-lighter);
    margin: var(--size-8);
}

.editor-canvas [data-contents] {
    display: flex;
    flex-direction: column;
}

.new-block-caret {
}

.editor-canvas a {
    color: var(--link-color);
}

.editor-canvas .text-unstyled {
    /*max-width: 40em;*/
}

.editor-canvas.read-only [data-block].placeholder {
    display: none;
}

.placeholder {
    position: relative;
    --o: 0;
}

.editor-fill {
    cursor: text;
    flex: 1;
    min-height: 400px;
}

[data-block].placeholder .public-DraftStyleDefault-block:before {
    content: var(--placholder-text);
    pointer-events: none;
    opacity: var(--o);
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    transition: opacity .1s;
}

.text-code-block {
    background-color: var(--dark-color);
    color: white;
    border-radius: var(--border-radius);
    padding: var(--size-4);
}

.editor-popover {
    background-color: white;
    box-shadow: 0 10px 24px 10px var(--dark-shadow-color);
    border-radius: var(--border-radius);
}
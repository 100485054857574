.cms-theme .MuiListItem-container {
    max-width: none;
}

.cms-theme .MuiTypography-body1,
.cms-theme .MuiTypography-body2,
.cms-theme .MuiButton-root,
.cms-theme .MuiInputBase-root,
.cms-theme .MuiFormLabel-root {
    font-family: inherit;
}

.cms-theme .MuiButton-containedPrimary {
    background-color: var(--primary-color);
}

.cms-theme .MuiInputBase-root .select {
    width: auto;
}

.cms-theme .MuiPopover-paper {
    background-color: var(--popover-bg-color);
    color: var(--text-color);
}

.cms-theme .MuiIconButton-root.inline {
    margin-top: -8px;
    margin-bottom: -8px;
}

.cms-theme .avatar-lg {
    width: 48px;
    height: 48px;
    aspect-ratio: 1;
    font-size: 1.6rem;
}

.cms-theme .form-section .MuiList-padding {
    padding: 0;
}

.form-section.variant-contained:has(.MuiList-padding) {
    gap: 0;
}

.cms-theme .MuiButton-outlinedPrimary {
    border-color: rgba(var(--primary-color-rgb), 0.5);
    color: var(--primary-color);
}

.cms-theme .MuiButton-outlinedPrimary:hover {
    border-color: rgba(var(--primary-color-rgb), 1);
    background-color: rgba(var(--primary-color-rgb), 0.05);
}

.cms-theme .MuiButton-outlinedSecondary {
    border-color: rgba(var(--success-color-rgb), 0.5);
    color: var(--success-color);
}

.cms-theme .MuiButton-outlinedSecondary:hover {
    border-color: rgba(var(--success-color-rgb), 1);
    background-color: rgba(var(--success-color-rgb), 0.05);
}

.cms-theme .MuiButton-outlinedSizeSmall {
    border-radius: 30px;
    padding: 4px 16px;
}
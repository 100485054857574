.MuiDialog-paper {
    overflow-x: hidden;
}

.joynt-theme .MuiBackdrop-root {
    background-color: rgba(25,5,48,0.9);
}

.joynt-theme .MuiPaper-root {
    color: inherit;
}

.joynt-theme .MuiPaper-elevation8 {
    box-shadow: 0 10px 20px var(--dark-shadow-color);
}

.joynt-theme .MuiOutlinedInput-root {
    border-radius: 10px;
}

.joynt-theme .MuiOutlinedInput-notchedOutline {
    border-color: #bcabcf;
}

.joynt-theme .MuiFormLabel-root {
    color: #bcabcf;
}

.joynt-theme .MuiInputBase-root {
    color: var(--text-color);
}

.joynt-theme .MuiBadge-colorPrimary {
    background-color: var(--primary-dark-2);
}

.joynt-theme .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color);
}

.joynt-theme .MuiButton-root {
    border-radius: 36px;
    text-transform: none;
    font-size: 14px;
}

.joynt-theme .MuiButton-contained {
    box-shadow: none;
}

.joynt-theme .MuiButton-contained:hover {
    box-shadow: none;
}

.joynt-theme .MuiButton-containedPrimary:hover {
    box-shadow: none;
}

.joynt-theme .MuiButton-containedSizeLarge,
.joynt-theme .MuiButton-outlinedSizeLarge {
    font-size: 18px;
    text-transform: none;
    padding: 8px 32px;
}

.joynt-theme .MuiButton-outlinedSizeLarge {
    padding: 6px 30px;
}

.joynt-theme .MuiButton-contained {
    background-color: var(--primary-lighter);
    color: var(--text-color);
}

.joynt-theme .MuiButton-contained:hover {
    background-color: var(--primary-light);
    color: var(--text-color);
}

.joynt-theme .MuiButton-containedPrimary {
    background-color: var(--primary-color);
    color: white;
}

.joynt-theme .MuiButton-containedPrimary:hover {
    background-color: var(--primary-dark);
    color: white;
}

.joynt-theme .MuiButton-contained.Mui-disabled {
    background-color: var(--primary-lightest);
    color: var(--primary-light)
}

.joynt-theme .MuiButton-text {
    color: var(--text-color);
}

.joynt-theme .MuiButton-textPrimary {
    color: var(--link-color);
    font-weight: bold;
}

.joynt-theme .MuiIconButton-root:hover {
    background-color: var(--primary-lightest);
}

.joynt-theme .MuiListItemIcon-root {
    color: var(--text-color);
    opacity: 0.5;
}

.joynt-theme .MuiListItem-button:hover {
    background-color: var(--primary-lightest);
}

.joynt-theme .MuiListItem-root.Mui-selected,
.joynt-theme .MuiListItem-root.Mui-selected:hover {
    background-color: var(--primary-lighter);
}

.joynt-theme .MuiTypography-colorTextSecondary {
    color: var(--text-color);
    opacity: 0.5;
}

.joynt-theme .MuiBottomNavigationAction-root {
    color: var(--primary-light);
}

.MuiTooltip-tooltip.MuiTooltip-tooltip {
    font-size: 11px;
    padding: 4px 8px;
    background-color: var(--primary-dark-transparent);
}

.MuiTooltip-arrow.MuiTooltip-arrow {
    color: var(--primary-dark-transparent);
}

.MuiButton-root + .MuiBadge-anchorOriginTopRightCircle {
    top: 2px;
    right: 10px;
}

.MuiButton-text + .MuiBadge-dot {
    right: -6px;
    top: 18px;
}

.MuiInputBase-root.cursor-pointer, .cursor-pointer .MuiInputBase-input {
    cursor: pointer;
}

.joynt-theme .MuiBadge-dot {
    border: solid 2px var(--bg-color);
    box-shadow: none;
    min-width: 12px;
    height: 12px;
    border-radius: 12px;
}

.joynt-theme .MuiSnackbarContent-root {
    color: white;
}

.joynt-theme .MuiChip-root {
    background-color: var(--primary-lighter);
    color: var(--text-color);
}

.joynt-theme .MuiChip-deleteIcon {
    font-size: 16px;
    color: inherit;
}

.MuiIconButton-root.outlined {
    border: solid 1px;
}

.joynt-theme .MuiButton-outlinedPrimary {
    color: var(--primary-color);
    border: solid 1px;
}

.joynt-theme .MuiButton-outlinedPrimary:hover {
    border: solid 1px;
}
.modal__overlay {
    display: none;
}

@media only screen and (min-width: 801px) {

    .modal__overlay {
        display: block;
        background: radial-gradient(ellipse at center, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0.65) 100%);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 4;
    }

    .modal__body {
        border-radius: 4px;
        width: 800px;
        height: 800px;
        position: fixed;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        z-index: 5;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.6);
    }

}
.joynt-theme .sidebar,
.sidebar {
    width: var(--sidebar-width);
    min-width: var(--sidebar-width);
    overflow: hidden auto;
    transition: all .3s;
}

.joynt-mobile .secondary-sidebar {
    position: fixed;
    top: 60px;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform: translateX(100%);
    transition: all .3s;
}

.show-sec-sidebar .secondary-sidebar {
    transform: translateX(0);
}



.joynt-theme .sidebar,
.joynt-theme .secondary-sidebar {
    border-radius: 0;
}


.joynt-mobile .sidebar {
    transform: translateX(-100%);
    position: fixed;
    top: 60px;
    left: 0;
    z-index: 2;
    max-height: calc(var(--100vh) - 60px);
    padding: 10px 10px 10px 5px;
    width: 260px;
}

.joynt-mobile .show-sidebar .sidebar {
    transform: translateX(60px);
}
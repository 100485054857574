.debug-x-ray [data-type="section"] * {
    filter: grayscale(1);
}

.debug-x-ray * {
    background-image: none;
    background-color: rgba(0,0,0,0.1) !important;
}

.debug-outline * {
    outline: solid 1px rgba(0,0,0,0.1);
}

.debug-outline [data-inspect][data-type="section"] {
    outline: solid 1px red;
}

.debug-outline [data-inspect][data-type] {
    outline: solid 1px var(--primary-color);
}

.debug-outline [data-inspect][data-type="layout"] {
    outline: solid 1px rgba(0,0,0,0.6);
}
.poll {
    --poll-answer-padding: 1rem 1rem;
    --poll-bar-opacity: 0.2;
}

.session-nav-content .poll-responses {
    display:none;
}

.session-nav-content .message--chat {
    max-width: 230px;
}

.session-nav-content  .own-message.message--chat {
    max-width: 187px;
}


.dark-mode .poll {
    --poll-bar-opacity: 1;
}

.dark-mode .poll-bar {
    border: none;
    background-color: var(--primary-light);
}

.poll-bar, .poll-option {
    border: solid 1px;
    border-radius: var(--border-radius);
    padding: var(--poll-answer-padding);
    flex-grow: 1;
    position: relative;
    overflow: hidden;
}

.poll-option {
    cursor: pointer;
    border-color: var(--primary-light);
    transition: all .3s;
}

.poll-option:hover {
    border-color: var(--primary-color);
}

.poll-option.is-selected {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
}

.poll-responses {
    width: 80px;
}

.poll-bar-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: var(--poll-bar-opacity);
    transition: all .3s;
}

.poll-answer.enabled {
    cursor: pointer;
}

.poll-answer:enabled:hover {
    border-color: var(--primary-color);
}

.poll-answer.enabled .poll-bar:before {
    content: 'Vote';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all .3s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;
}

.poll-answer.enabled:hover .poll-bar:before {
    opacity: 1;
}

.poll-answer:nth-child(3n+1) .poll-bar-bg {
    background-color: var(--theme-teal);
}

.poll-answer:nth-child(3n+2) .poll-bar-bg {
    background-color: var(--theme-pink);
}

.poll-answer:nth-child(3n+3) .poll-bar-bg {
    background-color: var(--theme-deep-purple);
}

.poll-bar-label {
    z-index: 1;
    transform: translateZ(0);
}

.responses-count {
    color: var(--link-color);
    font-size: 14px;
    font-weight: bold;
}
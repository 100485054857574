.select {
    width: 100%;
}

.select-options {
    max-height: 300px;
    overflow: hidden auto;
}

.select-value {
    flex: 1;
}

.select-tags .drop-target {
    flex-wrap: wrap;
    flex-direction: row;
    padding: 8px 0 8px 0;
    flex: 1;
    display: flex;
}

.select-tags .drop-target > * + * {
    margin-left: 4px;
}

.select-chip {
    background-color: rgba(255,255,255,0.1);
    margin-right: 2px;
    margin-bottom: 2px;
    border-radius: 2px;
    transition: all .3s;
    font-size: 11px;
    display: flex;
}

.select-chip:hover {
    background-color: var(--primary-color);
    box-shadow: 1px 2px 4px rgba(0,0,0,0.1);
}

.chip-label {
    padding: 3px 6px;
}

.chip-delete {
    font-size: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.1);
    margin-left: 2px;
    padding: 0 6px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}
.page-modal {
    position: fixed;
    top: 0;
    left: 56px;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: linear-gradient(var(--bg-color), var(--bg-color)), linear-gradient(white, white);
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1.1) translateX(1rem);
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
}

.joynt-mobile .page-modal {
    left: 0;
    bottom: var(--bottom-nav-height);
}

.page-modal.is-open {
    transform: none;
    opacity: 1;
    pointer-events: all;
}

.page-modal .edit-form {
    padding: 0;
}

.edit-form {
    padding: 0;
}
.joynt-mobile {
    --bottom-nav-height: 77px;
    --nav-color: var(--primary-dark-1);

    position: fixed;
    overflow: hidden;
}

.joynt-mobile .app-screen {
    height: 100%;
    width: 100vw;
    overflow: hidden auto;
    display: flex;
    flex-flow: column;
}

.joynt-mobile .sidebar-layout {
    position: fixed;
    transform: translateX(-100%);
    width: 88vw;
    max-width: 400px;
    z-index: 3;
    transition: transform 0.3s;
    height: var(--100vh);
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .joynt-mobile .app-screen,
    .joynt-mobile .joynt-layout,
    .joynt-mobile .sidebar-layout,
    .joynt-tablet .sidebar-layout  {
        height: -webkit-fill-available;
    }
}

.joynt-mobile .joynt-layout.show-sidebar .sidebar-layout {
    transform: translateX(0);
}

.joynt-mobile .view {
    padding: var(--size-5);
}

.joynt-mobile .header,
.joynt-tablet .header {
    height: var(--header-height);
    min-height: var(--header-height);
    background-color: var(--nav-color);
    color: white;
    z-index: 3;
    display: flex;
    padding: 0 16px 0 10px;
}

.joynt-mobile .bottom-nav, .joynt-tablet .bottom-nav {
    height: var(--bottom-nav-height);
    background: var(--nav-color);
}

.joynt-mobile .bottom-nav button {
    font-size: var(--size-6);
}

.joynt-tablet .bottom-nav .MuiBottomNavigation-root,
.joynt-mobile .bottom-nav .MuiBottomNavigation-root {
    height: 100%;
    background: transparent;
}

.joynt-mobile .bottom-nav .MuiBottomNavigationAction-label {
    line-height: 1.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    font-size: var(--size-4);
    color: white;
}

.joynt-tablet .bottom-nav .MuiBottomNavigationAction-root,
.joynt-mobile .bottom-nav .MuiBottomNavigationAction-root {
    color: white;
    opacity: 0.45;
}

.joynt-mobile .bottom-nav .MuiBottomNavigationAction-label {
    position: relative;
    overflow: visible;
}

.joynt-mobile .bottom-nav .MuiBottomNavigationAction-label:after {
    content: '';
    height: 3px;
    border-radius: 3px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s;
    pointer-events: none;
    position: absolute;
    background-color: var(--theme-pink);
    bottom: -6px;
    left: 0;
}

.joynt-mobile .bottom-nav .MuiBottomNavigationAction-root.Mui-selected,
.joynt-mobile .bottom-nav .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label:after {
    opacity: 1;
}

.joynt-mobile .phone-header,
.joynt-tablet .phone-header {
    display: flex;
    width: 100%;
}

.joynt-mobile .phone-header__display-name {
    max-width: 16em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.joynt-mobile .view.padded,
.joynt-mobile .mobile-wide {
    padding: 0;
}


.joynt-mobile .joynt-layout.show-sidebar .backdrop,
.joynt-mobile .joynt-layout.show-sec-sidebar .backdrop {
    background-color: #f5f2f8;
    opacity: 1;
    pointer-events: all;
}

.joynt-mobile .joynt-layout .view {
    /*transition: transform .3s;*/
    padding: 0;
}

.joynt-mobile .joynt-layout.show-sidebar .view {
    transform: translateX(40px);
}

.joynt-mobile .joynt-layout.show-sec-sidebar .view {
    transform: translateX(-40px);
}

.bottom-nav {
    position: static;
    z-index: 1;
    left: 0;
    right: 0;
}

.joynt-mobile .MuiBottomNavigationAction-root {
    min-width: 0;
    padding: 6px 6px 8px;
}
.DayPicker__withBorder {
    box-shadow: none;
    border-radius: 0;
}

.CalendarMonth_caption {
    padding-bottom: 56px;
}

.DayPickerKeyboardShortcuts_show {
    display: none !important;
}

.DayPicker_weekHeader_li {
    padding: 6px 0;
}

.CalendarDay {
    transition: all .2s;
}

.CalendarDay__selected_span {
    background: rgba(var(--primary-color-rgb), 0.3);
    color: var(--primary-color);
    border-color: var(--primary-color);
}

.CalendarDay__selected {
    background: var(--success-color);
    border-color: var(--success-color);
    color: white;
}

.CalendarDay__selected:hover {
    background: var(--success-color);
    border-color: var(--success-color);
    color: white;
}

.CalendarDay__selected_span:hover {
    background: var(--dark-color);
    border-color: var(--dark-color);
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: rgba(var(--primary-color-rgb), 0.3);
    color: var(--primary-color);
    border-color: var(--primary-color);
}
@import "mobile.css";

.inbox {
    display: flex;
    flex: 1;
    padding: var(--size-5);
    padding-right: var(--size-7);
    overflow: hidden;
    gap: var(--size-7);
}

.inbox-header {
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/*
    List styles
 */

.inbox-list {
    max-width: 388px;
    flex: 1;
    display: flex;
    flex-flow: column;
}

.inbox-threads {
    padding: 0 var(--size-3) var(--size-3);
    margin-top: var(--size-3);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.scroll-fade {
    overflow: hidden;
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
}

.scroll-fade:after, .scroll-fade:before {
    pointer-events: none;
    position: absolute;
    content: '';
    height: 100px;
    z-index: 1;
    transition: opacity .3s;
    left: 0;
    right: var(--scrollbar-width);
}

.scroll-fade:before {
    top: 0;
    background: linear-gradient(to bottom, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 0) 100%);
}

.dark-mode .scroll-fade:before {
    background: linear-gradient(to bottom, var(--primary-dark-1) 0%, rgba(243, 243, 243, 0) 100%);
}

.scroll-fade:after {
    bottom: 0;
    background: linear-gradient(to top, rgba(243, 243, 243, 1) 0%, rgba(243, 243, 243, 0) 100%);
}

.dark-mode .scroll-fade:after {
    bottom: 0;
    background: linear-gradient(to top, var(--primary-dark-1) 0%, rgba(243, 243, 243, 0) 100%);
}

.scroll-fade--start:before {
    opacity: 0;
    pointer-events: none;
}

.scroll-fade--end:after {
    opacity: 0;
    pointer-events: none;
}

/*
    Main section styles
 */

.inbox-list + .inbox-main {
    flex: 1;
}

.inbox-main {
    border-radius: var(--border-radius);
    background: white;
    display: flex;
    justify-content: center;
}

.inbox-chat {
    flex: 1;
    max-width: 645px;
    padding: 0 var(--size-3);
}
/* fixme - try to avoid using media queries */
@media only screen and (min-width: 1461px) {
    .joynt-desktop .inbox-main .chat {
        max-width: 440px;
    }
}

@media only screen and (min-width: 1675px) {
    .joynt-desktop .inbox-main .chat {
        max-width: 100%;
    }
}

.inbox-main .chat {
    background: none;
    border-radius: unset;
    padding: 0;
}

.inbox-post {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    max-width: 40em;
    overflow: hidden;
    padding: var(--size-6) var(--size-7) var(--size-5);
}

.inbox-post .post-view .post-title {
    font-weight: bold;
    font-size: var(--font-size-lead);
}

.inbox-post .post-thread {
    max-height: 66%;
}

.inbox-post .post-view {
    display: flex;
    flex: 1;
    flex-flow: column;
    overflow: hidden;
}

.inbox-post .post-content {
    flex: 1;
    overflow: auto;
    padding-right: var(--size-3);
}

.inbox-post .message-form {
    position: sticky;
    bottom: 0;
    background: white;
    padding-top: var(--size-3);
}

.inbox-post .post-comments__list {
    overflow-y: auto;
    padding-right: var(--size-3);
}

::-webkit-scrollbar-track {
    background-color: transparent;
    padding: var(--scrollbar-width);
}
::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: var(--scrollbar-width);
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 6px;
    cursor: pointer;
    transition: background-color .3s;
}
::-webkit-scrollbar-thumb:hover {
    background-color: var(--success-color);
}
:root {
    /* define a set of units/magnitudes that represent
    visual rhythm in our design system*/
    --value-1: 2;
    --value-2: 4;
    --value-3: 8;
    --value-4: 12;
    --value-5: 16;
    --value-6: 24;
    --value-7: 32;
    --value-8: 40;
    --value-9: 56;
    --value-10: 64;
    --value-11: 80;
    /*we need a bonus unit so that the number of sizes is nice*/
    --value-12: 96;


    /*sizes are pixelized measures using above values*/
    --size-1: calc(var(--value-1) * 1px);
    --size-2: calc(var(--value-2) * 1px);
    --size-3: calc(var(--value-3) * 1px);
    --size-4: calc(var(--value-4) * 1px);
    --size-5: calc(var(--value-5) * 1px);
    --size-6: calc(var(--value-6) * 1px);
    --size-7: calc(var(--value-7) * 1px);
    --size-8: calc(var(--value-8) * 1px);
    --size-9: calc(var(--value-9) * 1px);
    --size-10: calc(var(--value-10) * 1px);
    --size-11: calc(var(--value-11) * 1px);
    --size-12: calc(var(--value-12) * 1px);

    /* rem size */
    --rem-value: 14;
    --rem-size: calc(var(--rem-value) * 1px);

    /* values expressed as relative units */
    --rem-size-1: calc(var(--value-1) / var(--rem-value) * 1rem);
    --rem-size-2: calc(var(--value-2) / var(--rem-value) * 1rem);
    --rem-size-3: calc(var(--value-3) / var(--rem-value) * 1rem);
    --rem-size-4: calc(var(--value-4) / var(--rem-value) * 1rem);
    --rem-size-5: calc(var(--value-5) / var(--rem-value) * 1rem);
    --rem-size-6: calc(var(--value-6) / var(--rem-value) * 1rem);
    --rem-size-7: calc(var(--value-7) / var(--rem-value) * 1rem);
    --rem-size-8: calc(var(--value-8) / var(--rem-value) * 1rem);
    --rem-size-9: calc(var(--value-9) / var(--rem-value) * 1rem);
    --rem-size-10: calc(var(--value-10) / var(--rem-value) * 1rem);
    --rem-size-11: calc(var(--value-11) / var(--rem-value) * 1rem);
    --rem-size-12: calc(var(--value-12) / var(--rem-value) * 1rem);


    /* font sizes, defined as absolute values, do not use them for styling margins, paddings and distances */
    --font-size-hero-heading: 56px;
    --font-size-h1-heading: 32px;
    --font-size-h2-heading: 24px;
    --font-size-h3-heading: 18px;
    --font-size-lead: 16px;
    --font-size-paragraph: 14px;
    --font-size-small: 12px;
    --font-size-tiny: 10px;

    font-size: 16px; /** var(--rem-size); **/
}


h1 {
    font-size: var(--font-size-h1-heading);
}


h2 {
    font-size: var(--font-size-h2-heading);
}


h3 {
    font-size: var(--font-size-h3-heading);
}

.type-lg {
    font-size: var(--font-size-h3-heading);
}

/*
    use values in grid components
*/


.grid.gap-1, .grid.gap-2, .grid.gap-3,
.grid.gap-4, .grid.gap-5, .grid.gap-6,
.grid.gap-7, .grid.gap-8, .grid.gap-9,
.grid.gap-10, .grid.gap-11, .grid.gap-12 {
    grid-gap: var(--grid-gap);
}

.grid.gap-1 {
    --grid-gap: var(--size-1)
}

.grid.gap-2 {
    --grid-gap: var(--size-2)
}

.grid.gap-3 {
    --grid-gap: var(--size-3)
}

.grid.gap-4 {
    --grid-gap: var(--size-4)
}

.grid.gap-5 {
    --grid-gap: var(--size-5)
}

.grid.gap-6 {
    --grid-gap: var(--size-6)
}

.grid.gap-7 {
    --grid-gap: var(--size-7)
}

.grid.gap-8 {
    --grid-gap: var(--size-8)
}

.grid.gap-9 {
    --grid-gap: var(--size-9)
}

.grid.gap-10 {
    --grid-gap: var(--size-10)
}

.grid.gap-11 {
    --grid-gap: var(--size-11)
}

.grid.gap-12 {
    --grid-gap: var(--size-12)
}

.rows.gap-1 > :not(:first-child), .rows.gap-2 > :not(:first-child), .rows.gap-3 > :not(:first-child),
.rows.gap-4 > :not(:first-child), .rows.gap-5 > :not(:first-child), .rows.gap-6 > :not(:first-child),
.rows.gap-7 > :not(:first-child), .rows.gap-8 > :not(:first-child), .rows.gap-9 > :not(:first-child),
.rows.gap-10 > :not(:first-child), .rows.gap-11 > :not(:first-child), .rows.gap-12 > :not(:first-child) {
    margin-top: var(---gap);
}

.cols.gap-1 > :not(:first-child), .cols.gap-2 > :not(:first-child), .cols.gap-3 > :not(:first-child),
.cols.gap-4 > :not(:first-child), .cols.gap-5 > :not(:first-child), .cols.gap-6 > :not(:first-child),
.cols.gap-7 > :not(:first-child), .cols.gap-8 > :not(:first-child), .cols.gap-9 > :not(:first-child),
.cols.gap-10 > :not(:first-child), .cols.gap-11 > :not(:first-child), .cols.gap-12 > :not(:first-child) {
    margin-left: var(---gap);
}

.gap-1 {
    ---gap: var(--size-1)
}

.gap-2 {
    ---gap: var(--size-2)
}

.gap-3 {
    ---gap: var(--size-3)
}

.gap-4 {
    ---gap: var(--size-4)
}

.gap-5 {
    ---gap: var(--size-5)
}

.gap-6 {
    ---gap: var(--size-6)
}

.gap-7 {
    ---gap: var(--size-7)
}

.gap-8 {
    ---gap: var(--size-8)
}

.gap-9 {
    ---gap: var(--size-9)
}

.gap-10 {
    ---gap: var(--size-10)
}

.gap-11 {
    ---gap: var(--size-11)
}

.gap-12 {
    ---gap: var(--size-12)
}



.pad-1, .pad-2, .pad-3,
.pad-4, .pad-5, .pad-6,
.pad-7, .pad-8, .pad-9,
.pad-10, .pad-11, .pad-12 {
    padding: var(--padding);
}

.pad-1 {
    --padding: var(--size-1)
}

.pad-2 {
    --padding: var(--size-2)
}

.pad-3 {
    --padding: var(--size-3)
}

.pad-4 {
    --padding: var(--size-4)
}

.pad-5 {
    --padding: var(--size-5)
}

.pad-6 {
    --padding: var(--size-6)
}

.pad-7 {
    --padding: var(--size-7)
}

.pad-8 {
    --padding: var(--size-8)
}

.pad-9 {
    --padding: var(--size-9)
}

.pad-10 {
    --padding: var(--size-10)
}

.pad-11 {
    --padding: var(--size-11)
}

.pad-12 {
    --padding: var(--size-12)
}


.pad-side-1, .pad-side-2, .pad-side-3,
.pad-side-4, .pad-side-5, .pad-side-6,
.pad-side-7, .pad-side-8, .pad-side-9,
.pad-side-10, .pad-side-11, .pad-side-12 {
    padding-left: var(--padding-side);
    padding-right: var(--padding-side);
}

.pad-side-1 {
    --padding-side: var(--size-1)
}

.pad-side-2 {
    --padding-side: var(--size-2)
}

.pad-side-3 {
    --padding-side: var(--size-3)
}

.pad-side-4 {
    --padding-side: var(--size-4)
}

.pad-side-5 {
    --padding-side: var(--size-5)
}

.pad-side-6 {
    --padding-side: var(--size-6)
}

.pad-side-7 {
    --padding-side: var(--size-7)
}

.pad-side-8 {
    --padding-side: var(--size-8)
}

.pad-side-9 {
    --padding-side: var(--size-9)
}

.pad-side-10 {
    --padding-side: var(--size-10)
}

.pad-side-11 {
    --padding-side: var(--size-11)
}

.pad-side-12 {
    --padding-side: var(--size-12)
}


.pad-vert-1, .pad-vert-2, .pad-vert-3,
.pad-vert-4, .pad-vert-5, .pad-vert-6,
.pad-vert-7, .pad-vert-8, .pad-vert-9,
.pad-vert-10, .pad-vert-11, .pad-vert-12 {
    padding-top: var(--padding-vert);
    padding-bottom: var(--padding-vert);
}

.pad-vert-1 {
    --padding-vert: var(--size-1)
}

.pad-vert-2 {
    --padding-vert: var(--size-2)
}

.pad-vert-3 {
    --padding-vert: var(--size-3)
}

.pad-vert-4 {
    --padding-vert: var(--size-4)
}

.pad-vert-5 {
    --padding-vert: var(--size-5)
}

.pad-vert-6 {
    --padding-vert: var(--size-6)
}

.pad-vert-7 {
    --padding-vert: var(--size-7)
}

.pad-vert-8 {
    --padding-vert: var(--size-8)
}

.pad-vert-9 {
    --padding-vert: var(--size-9)
}

.pad-vert-10 {
    --padding-vert: var(--size-10)
}

.pad-vert-11 {
    --padding-vert: var(--size-11)
}

.pad-vert-12 {
    --padding-vert: var(--size-12)
}


.pad-top-1, .pad-top-2, .pad-top-3,
.pad-top-4, .pad-top-5, .pad-top-6,
.pad-top-7, .pad-top-8, .pad-top-9,
.pad-top-10, .pad-top-11, .pad-top-12 {
    padding-top: var(--padding-top);
}

.pad-top-1 {
    --padding-top: var(--size-1)
}

.pad-top-2 {
    --padding-top: var(--size-2)
}

.pad-top-3 {
    --padding-top: var(--size-3)
}

.pad-top-4 {
    --padding-top: var(--size-4)
}

.pad-top-5 {
    --padding-top: var(--size-5)
}

.pad-top-6 {
    --padding-top: var(--size-6)
}

.pad-top-7 {
    --padding-top: var(--size-7)
}

.pad-top-8 {
    --padding-top: var(--size-8)
}

.pad-top-9 {
    --padding-top: var(--size-9)
}

.pad-top-10 {
    --padding-top: var(--size-10)
}

.pad-top-11 {
    --padding-top: var(--size-11)
}

.pad-top-12 {
    --padding-top: var(--size-12)
}

.pad-bottom-1, .pad-bottom-2, .pad-bottom-3,
.pad-bottom-4, .pad-bottom-5, .pad-bottom-6,
.pad-bottom-7, .pad-bottom-8, .pad-bottom-9,
.pad-bottom-10, .pad-bottom-11, .pad-bottom-12 {
    padding-bottom: var(--padding-bottom);
}

.pad-bottom-1 {
    --padding-bottom: var(--size-1)
}

.pad-bottom-2 {
    --padding-bottom: var(--size-2)
}

.pad-bottom-3 {
    --padding-bottom: var(--size-3)
}

.pad-bottom-4 {
    --padding-bottom: var(--size-4)
}

.pad-bottom-5 {
    --padding-bottom: var(--size-5)
}

.pad-bottom-6 {
    --padding-bottom: var(--size-6)
}

.pad-bottom-7 {
    --padding-bottom: var(--size-7)
}

.pad-bottom-8 {
    --padding-bottom: var(--size-8)
}

.pad-bottom-9 {
    --padding-bottom: var(--size-9)
}

.pad-bottom-10 {
    --padding-bottom: var(--size-10)
}

.pad-bottom-11 {
    --padding-bottom: var(--size-11)
}

.pad-bottom-12 {
    --padding-bottom: var(--size-12)
}


.pad-right-1, .pad-right-2, .pad-right-3,
.pad-right-4, .pad-right-5, .pad-right-6,
.pad-right-7, .pad-right-8, .pad-right-9,
.pad-right-10, .pad-right-11, .pad-right-12 {
    padding-right: var(--padding-right);
}

.pad-right-1 {
    --padding-right: var(--size-1)
}

.pad-right-2 {
    --padding-right: var(--size-2)
}

.pad-right-3 {
    --padding-right: var(--size-3)
}

.pad-right-4 {
    --padding-right: var(--size-4)
}

.pad-right-5 {
    --padding-right: var(--size-5)
}

.pad-right-6 {
    --padding-right: var(--size-6)
}

.pad-right-7 {
    --padding-right: var(--size-7)
}

.pad-right-8 {
    --padding-right: var(--size-8)
}

.pad-right-9 {
    --padding-right: var(--size-9)
}

.pad-right-10 {
    --padding-right: var(--size-10)
}

.pad-right-11 {
    --padding-right: var(--size-11)
}

.pad-right-12 {
    --padding-right: var(--size-12)
}


.pad-left-1, .pad-left-2, .pad-left-3,
.pad-left-4, .pad-left-5, .pad-left-6,
.pad-left-7, .pad-left-8, .pad-left-9,
.pad-left-10, .pad-left-11, .pad-left-12 {
    padding-left: var(--padding-left);
}

.pad-left-1 {
    --padding-left: var(--size-1)
}

.pad-left-2 {
    --padding-left: var(--size-2)
}

.pad-left-3 {
    --padding-left: var(--size-3)
}

.pad-left-4 {
    --padding-left: var(--size-4)
}

.pad-left-5 {
    --padding-left: var(--size-5)
}

.pad-left-6 {
    --padding-left: var(--size-6)
}

.pad-left-7 {
    --padding-left: var(--size-7)
}

.pad-left-8 {
    --padding-left: var(--size-8)
}

.pad-left-9 {
    --padding-left: var(--size-9)
}

.pad-left-10 {
    --padding-left: var(--size-10)
}

.pad-left-11 {
    --padding-left: var(--size-11)
}

.pad-left-12 {
    --padding-left: var(--size-12)
}


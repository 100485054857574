.ui-preloader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    transition: opacity .1s;
    pointer-events: none;
    opacity: 0;
    z-index: 5;
}

.ui-preloader--linear {
    align-items: flex-start;
}

.ui-preloader--linear .ui-preloader__indicator {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
}

.ui-preloader.is-visible {
    opacity: 1;
}
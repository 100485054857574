.feed-empty-state {
    padding: 3rem;
    font-size: 18px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    max-width: 100%;
}

.feed-empty-state__heading {
    opacity: 0.2;
    text-align: center;
}

.feed-list--compact.edit .post:not(.is-edited) {
    opacity: 0.4;
    pointer-events: none;
    filter: blur(6px);
}

.empty-state-illustration {
    width: 300px;
    height: 225px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: 100%;
}

.feed-header__description {
    font-weight: normal;
    font-size: var(--font-size-lead);
    opacity: 0.8;
    line-height: 1em;
}
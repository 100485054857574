.field-mapping {
    position: relative;
}

.field-mapping.is-mapped {
    border: solid 1px var(--primary-color);
    border-radius: 2px;
}

.field-mapping.is-mapped:after {
    content: '';
    position: absolute;
    background-color: var(--primary-color);
    opacity: 0.2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.field-mapping.is-mapped .field-content {
    pointer-events: none;
}
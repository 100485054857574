.profile-card {
    min-width: 160px;
    min-height: 220px;
    cursor: pointer;
}

.profile-card__name {
    font-size: 14px;
}

.profile-card__company {
    opacity: .5;
    font-size: 12px;
}

.find-user__location {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: translateY(-125%);
    opacity: 0;
    background-color: var(--primary-color);
    padding: 10px 16px;
    border-radius: var(--border-radius);
    color: white;
    font-size: 12px;
    transition: all .3s;
    cursor: pointer;
    box-shadow: 0 10px 20px var(--dark-shadow-color);
}

.find-user:hover .find-user__location {
    transform: translateY(-100%);
    opacity: 1;
}
.buttonWrapper {
    display: inline-block;
}

.button {
    background: transparent;
    color: #fff;
    font-size: 14px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 32px;
    width: 24px;
    border-radius: 4px;
}

.button svg {
    fill: #fff;
    height: 18px;
    width: 18px;
}

.button:hover, .button:focus {
    background: var(--primary-dark-transparent);
    outline: 0; /* reset for :focus */
}

.active {
    color: var(--success-color);
}

.active svg {
    fill: var(--success-color);
}
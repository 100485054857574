.dev-stage .utility-nav-drawer {
    z-index: 2;
}

.dev-stage .stage-pagination {
    z-index: 2;
}

.dev-stage .video-stage {
    position: relative;
}
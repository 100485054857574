.quill {
    width: 100%;
}

.ql-bubble .ql-editor.ql-blank::before {
    left: 0;
}

.ql-snow .ql-editor {
    font-family: 'Montserrat';
    font-size: 14px;
}

.ql-snow .ql-editor {
    max-height: 400px;
}

/*.ql-snow .ql-editor > p + p {
    margin-top: 1.6rem;
}

.ql-snow .ql-editor > h1 + *,
.ql-snow .ql-editor > h2 + *,
.ql-snow .ql-editor > h3 + *,
.ql-snow .ql-editor > h4 + *,
.ql-snow .ql-editor > h5 + *,
.ql-snow .ql-editor > h6 + * {
    margin-top: 1em !important;
}*/

.ql-snow .ql-editor > * + * {
  margin-top: 1.2rem;
}
.ql-snow .ql-editor > li + li {
  margin-top: 0.8rem;
}

.ql-snow .ql-editor {
    padding: 1.6rem;
}

.ql-bubble .ql-editor {
    padding: 0;
}

.quill {
    margin-bottom: 3rem;
}

.quill-style-only {
    margin-bottom: 0;
}

.ql-container.ql-snow {
    margin-bottom: -42px;
}

.ql-container.ql-bubble {
    font-family: inherit;
    font-size: inherit;
}

.theme-dark .ql-toolbar.ql-snow,
.theme-dark .ql-container.ql-snow {
    border-color: rgba(255,255,255,0.23);
}

.theme-dark .ql-toolbar.ql-snow {
    background-color: rgba(255,255,255,0.05);
}

.theme-dark .ql-snow .ql-stroke {
    stroke: #ffffff;
}

.theme-dark .ql-snow .ql-fill {
    fill: #ffffff;
}

.theme-dark .ql-snow.ql-toolbar button:hover,
.theme-dark .ql-snow.ql-toolbar button:hover .ql-stroke {
    color: var(--primary-color);
    stroke: var(--primary-color);
}
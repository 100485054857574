.role-select {
    background-color: var(--primary-lighter);
    border-radius: 4px;
    padding: 4px 8px;
    font-weight: bold;
    cursor: pointer;
    transition: all .3s;
}

.role-select:hover {
    background-color: var(--primary-light);
}

.identity-search-results {
    width: 340px;
    background-color: white;
    max-height: 300px;
    font-size: 12px;
}

.identity-search-results .type-lg {
    font-size: 1.2em;
}
.clamp-1,
.clamp-2,
.clamp-3,
.clamp-4,
.clamp-5,
.clamp-6,
.clamp-7,
.clamp-8,
.clamp-9,
.clamp-10 {
    display: -webkit-box;
    -webkit-line-clamp: var(--line-clamp);
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.clamp-1 {
    --line-clamp: 1;
}

.clamp-2 {
    --line-clamp: 2;
}

.clamp-3 {
    --line-clamp: 3;
}

.clamp-4 {
    --line-clamp: 4;
}

.clamp-5 {
    --line-clamp: 5;
}

.clamp-6 {
    --line-clamp: 6;
}

.clamp-7 {
    --line-clamp: 7;
}

.clamp-8 {
    --line-clamp: 8;
}

.clamp-9 {
    --line-clamp: 9;
}

.clamp-10 {
    --line-clamp: 10;
}
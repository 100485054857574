.rst__rowWrapper.rst__rowWrapper {
    padding: 4px 0 4px 0;
}

.rstcustom__rowTitle.rstcustom__rowTitle {
    font-weight: normal;
    font-size: 13px;
}

.rstcustom__collapseButton, .rstcustom__expandButton {
    padding-left: 10px;
}

.rst__virtualScrollOverride {
    padding: 0.6rem;
}

.rstcustom__nodeContent {
    width: 100%;
}

.rstcustom__rowContents {
    padding: 0;
}

.rstcustom__rowLabel {
    height: 100%;
    display: flex;
}

.rstcustom__rowTitle {
    flex: 1;
    display: flex;
}

.rstcustom__rowTitleContent {
    display: flex;
    flex: 1;
    align-items: center;
    padding-left: 1rem;
    background-color: transparent;
}

.rstcustom__highlight {
    background-color: transparent;
}

.rstcustom__rowLandingPad:before {
    background-color: transparent;
    border: 1px dotted rgba(255,255,255,0.2);
}

.rstcustom__rowCancelPad:before {
    background-color: red;
    opacity: 0.2;
}

.rstcustom__rowContents {
    box-shadow: none;
    /*box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);*/
    cursor: default;
    background-color: transparent;
}

.rstcustom__rowWrapper {
    cursor: default;
    padding: 0;
    width: 100%;
}

.rstcustom__rowContents:hover {
    box-shadow: none;
}

.rstcustom__rowWrapper:hover {
    opacity: 1;
    background-color: rgba(255,255,255,0.1);
}

.rstcustom__collapseButton,
.rstcustom__expandButton {
    color: var(--invert-color);
    opacity: 0.8;
    font-size: 10px;
}

.card-list-item {
    cursor: pointer;
    transition: all .3s;
}

.new-component-label {
    opacity: 0.3;
    font-style: italic;
}

.is-disabled {
    opacity: 0.5;
    text-decoration: line-through;
}
.spaces-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 220px;
    grid-gap: 1.4rem;
}

.spaces-groups-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.4rem;
}

.tile {
    background-color: var(--light-color);
    background-size: cover;
    border-radius: var(--border-radius);
    cursor: pointer;
    min-width: 200px;
    font-size: 12px;
    padding: 24px 12px 12px 12px;
    position: relative;
    overflow: hidden;
    transition: all .3s;
}

.joynt-theme .tile.tile--placeholder {
    background-color: transparent;
    box-shadow: none;
    border: solid 1px var(--primary-light);
}

.tile--placeholder .tile-border {
    opacity: 0.1;
}

.tile:hover {
    box-shadow: inset 0 0 1px 1px var(--fg-color), 0 10px 20px var(--dark-shadow-color) !important;
}

.tile-content {
    color: var(--text-color);
}

.tile-border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 12px;
    opacity: 0.3;
}

.tile-border + * {
    margin-left: 0 !important;
}

.tile-name {
    font-weight: bold;
    letter-spacing: 0.6px;
    font-size: 14px;
    line-height: 1.6em;
}

.tile-subtitle {
    font-weight: bold;
    font-size: 12px;
    line-height: 1.6em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tile-description {
    font-size: 12px;
    line-height: 1.6em;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.tile-subtitle + .tile-description {
    -webkit-line-clamp: 3;
}

.tile.with-img, .tile.with-content {
    grid-column: auto / span 2;
}

.tile-skeleton {
    min-width: 340px;
    overflow: hidden;
    border-radius: var(--border-radius);
    height: 220px;
    width: 100%;
}

.tile-create {
    border: dashed 1px;
    border-color: var(--light-color);
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.tile-create:hover {
    background-color: rgba(0,0,0,0.03);
}

.tile-img {
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--primary-lightest);
    position: relative;
}

.tile-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.joynt-large-desktop .spaces-grid {
    grid-template-columns: repeat(4, 1fr);
}

.joynt-large-desktop .spaces-grid-2 {
    grid-template-columns: repeat(2, 1fr);
}

.joynt-large-desktop .tile, .tile-skeleton {
    min-width: 200px;
}

.file-grid {
    --size: 80px;
    --gap: 5px;
    --min-height: 0;
}

.file-grid--dense {
    --size: 50px;
    --gap: 2px;
}

.file-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(var(--size), 1fr));
    grid-auto-rows: var(--size);
    grid-gap: var(--gap);
    min-height: var(--min-height);
}

/*.file-grid[data-count="1"] {*/
/*    --min-height: 300px;*/
/*    display: flex;*/
/*}*/

/*.file-grid[data-count="1"] .media-file {*/
/*    width: 100%;*/
/*}*/

/*.file-grid[data-count="2"] {*/
/*    --min-height: 240px;*/
/*    grid-template-columns: repeat(2, 1fr);*/
/*    grid-auto-rows: var(--height);*/
/*}*/

/*.file-grid[data-count="3"] {*/
/*    --min-height: 200px;*/
/*    grid-template-columns: repeat(3, 1fr);*/
/*    grid-auto-rows: var(--min-height);*/
/*}*/

.media-file {
    border-radius: 4px;
    background-color: rgba(0,0,0,0.05);
    overflow: hidden;
    cursor: pointer;
    transition: all .3s;
    outline-color: var(--primary-color);
}

.media-file:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    opacity: 0;
    transform: scale(0);
    transform-origin: center center;
    z-index: 1;
    transition: all .3s;
    border-radius: 9999px;
}

.media-file:hover {
    box-shadow: 0 2px 18px rgba(0,0,0,0.4);
}

.media-file:hover:before {
    transform: scale(1.6);
    opacity: 0.2;
}

.media-upload-placeholder {
    background-color: rgba(0,0,0,0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .3s;
    transform-origin: center center;
}

.media-upload-placeholder:hover {
    transform: scale(0.9);
}

.media-delete {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 18px;
    padding: 4px;
    transform-origin: center center;
    transition: all .3s;
    z-index: 2;
}

.media-delete:hover {
    color: var(--primary-color);
}
.device-settings {
    overflow: hidden;
    max-width: 100vw;
}

.joynt-desktop .device-settings {
    max-width: 26rem;
}

.device-settings > * {
    z-index: 1;
}

.joynt-theme .device-settings.dark-mode {
    --bg-color: var(--primary-dark-1);
}

.MuiPaper-root .device-settings:before,
.MuiPaper-root .device-settings:after {
    display: none;
}

.stage-cover .device-settings.dark-mode {
    background-color: transparent;
}

.device-settings:before {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/illustrations/Planet_background.svg');
    background-repeat: no-repeat;
    width: 260px;
    height: 260px;
    position: absolute;
    left: -84px;
    top: -54px;

}

.device-settings:after {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/teal-circle.svg');
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) translateX(50%);
}

.cam-preview {
    width: 100%;
    height: 180px;
    position: relative;
    overflow: hidden;
    padding: 1rem;
    background-color: var(--primary-lightest);
}

.cam-preview.no-webcam {
    height: auto;
}

.cam-preview-controls {
    color: white;
}

.cam-preview-controls .session-mute:not(.on) {
    position: relative;
    background-color: white;
    color: var(--primary-dark);
}

.cam-preview-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.allow-media {
    height: 180px;
    background-size: cover;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/allow-media.png');
    background-repeat: no-repeat;
    border-radius: 12px;
    width: 100%;
    max-width: 100%;
}
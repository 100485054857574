.edit-modal {
    width: 620px;
    height: 720px;
    max-height: 90vh;
    max-width: 100vw;
}

.channel-type {
    padding: 1rem;
    border: solid 1px;
    border-color: var(--primary-lighter);
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s;
    background-color: rgba(255, 255, 255, 0.1);
}

.rows > .channel-type {
    padding: .6rem .8rem;
}

.channel-type:hover {
    border-color: var(--primary-light);
    color: var(--primary-color);
}

.channel-type.selected {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: white;
}

.channel-type .MuiIcon-root {
    font-size: 22px;
    opacity: 0.5;
}

.select-box {
    background-color: var(--primary-lighter);
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: all .3s;
}

.select-box.is-null .select-label {
    opacity: 0.5;
    font-style: italic;
}

.select-box:hover {
    background-color: var(--primary-light);
}

.select-lg {
    font-size: 14px;
    font-weight: normal;
}

.select-box .select-label {
    padding: 4px 8px 4px 8px;
}

.select-lg .select-label {
    padding: 6px 12px 6px 12px;
}

.text-edit {
    border: solid 1px;
    border-radius: var(--border-radius);
    padding: 1rem;
}

.text-edit .codex-editor__redactor {
    padding-bottom: 0 !important;
}

.workspace-type.workspace-type {
    border-radius: var(--border-radius);
    padding-left: 220px;
    padding-top: 60px;
    position: relative;
}

.joynt-mobile .workspace-type {
    padding: var(--size-6);
    align-items: center;
}

.workspace-type img {
    position: absolute;
    width: 260px;
    top: -10px;
    left: -64px;
}
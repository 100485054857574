.no-access .header {
    display: none;
}

.brand-no-access {
    width: 550px;
    height: 345px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: 80vw;
}
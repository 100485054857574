.text-content {
    font-size: 14px;
    line-height: 1.5em;
}

.text-content .content-block + .content-block {
    margin-top: 1em;
}

.text-content--truncated div {
    display: inline;
}

.text-content--truncated .content-block + .content-block {
    margin-top: 0;
    margin-left: 0.6em;
}

.text-content--sm {
    font-size: 12px;
}

.text-content--default > * + * {
    margin-top: 1em;
}

.text-content ul {
    padding-left: 3em;
}
.list-filter {
    background-color: rgba(255,255,255,0.1);
    border-radius: 4px;
    padding: 0.8rem 1rem;
}

.list-filter .search-input {
    background: none;
}

.list-filter .select-tags .drop-target {
    padding: 0;
}

.list-filter .select-inline:hover {
    outline: none;
    background:none;
}
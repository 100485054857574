.card {
    padding: 1rem;
    border-radius: var(--border-radius);
    --bg-color: white;
    background-color: var(--bg-color);
}

.card-border {
    padding: 1rem;
    border: solid 1px var(--primary-lighter);
    border-radius: var(--border-radius);
}

.card-border--link {
    cursor: pointer;
    transition: all .3s;
}

.card-border--link:hover {
    border-color: var(--primary-color);
    background-color: white;
}
.ide-context-menu {
    position: fixed;
    width: 200px;
    height: 300px;
    overflow: hidden auto;
    background-color: var(--default-color);
    box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    font-size: 13px;
    z-index: 99;
}

.ide-context-menu .menu-item {
    padding: 4px 8px;
    cursor: pointer;
    transition: all .3s;
}

.ide-context-menu .menu-item:hover {
    background-color: rgba(255,255,255,0.1);
}
div[role="tooltip"] {
    z-index: 3;
}

div[role="tooltip"][x-placement="top"] {
    z-index: 3;
}

.joynt-theme .popper {
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0 10px 20px var(--dark-shadow-color);
}

.popper:before {
    content: '';
    position: absolute;
    border: solid white;
    border-width: 0 10px 10px 0;
}

div[x-placement="top"] .popper {
    transform: translateY(-10px);
}

div[x-placement="top"] .popper:before {
    bottom: 0;
    left: 50%;
    transform: translateY(50%) translateX(-50%) rotate(-45deg);
}

div[x-placement="left"] .popper {
    transform: translateX(-10px);
}

div[x-placement="left"] .popper:before {
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(50%) rotate(-45deg);
}

div[x-placement="right"] .popper {
    transform: translateX(10px);
}

div[x-placement="right"] .popper:before {
    top: 50%;
    left: 0;
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}

div[x-placement="bottom"] .popper {
    transform: translateY(10px);
    box-shadow: 0 -6px 20px var(--dark-shadow-color);
}

div[x-placement="bottom"] .popper:before {
    top: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%) rotate(-45deg);
}
.input-checker {
    border: solid 1px;
    border-radius: 9999px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.input-checker:not(.checked) {
    color: rgba(0,0,0,0.5) !important;
    opacity: 0.4;
}

.input-checker:hover {
    border-color: var(--active-color);
    color: var(--active-color);
}

.input-checker.checked {
    color: white;
    border-color: var(--active-color);
    background-color: var(--active-color);
}

.input-checker--danger.checked {
    color: white;
    border-color: var(--danger-color);
    background-color: var(--danger-color);
}
.mention-identity {
    padding: 4px 12px;
    transition: all .3s;
}

.mention-identity:hover {
    background-color: var(--primary-lightest);
}

.mentions__suggestions {
    border-radius: var(--border-radius);
    padding: 4px 0;
    box-shadow: 0 4px 20px var(--dark-shadow-color);
}

.mentions__highlighter strong,
[data-mention] {
    background-color: var(--primary-lighter);
}

[data-mention] {
    color: var(--primary-color);
}

.dark-mode [data-mention] {
    color: var(--theme-pink);
}

[data-mention]:hover {
    background-color: var(--primary-light);
}

[data-mention] {
    padding: 0 4px;
    border-radius: 2px;
}

[data-mention][data-id] {cursor: pointer;}

.variant-chat [data-mention] {
    color: var(--primary-color);
}
.variant-chat .own-message [data-mention] {
    color: inherit;
    background-color: rgba(255,255,255,0.3);
}
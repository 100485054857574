.page-form {
    min-width: 600px;
}

.slug-field {
    font-size: 14px;
}

.slug-domain {
    opacity: 0.5;
}

.slug-edit {
    display: inline;
    background-color: rgba(0,0,0,0.2);
    padding: 2px 6px;
    border-radius: 2px;
}


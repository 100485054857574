.section-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    flex-grow: 1;
    overflow: hidden auto;
    padding: 20px;
    position: relative;

    --ui-text-color: black;
    --ui-success-color: #04B4A5;
    --ui-danger-color: red;
}

.sections-list-filter {
    padding: 20px;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.section-grid .status {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 2rem;
    text-align: center;
}

.section-grid.pending {
    padding-right: 24px;
}

.section-grid.pending .section-card {
    background: white;
}

.section-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    flex-grow: 1;
    overflow: hidden auto;
    padding: 30px;
    position: relative;
}
.style-element-panel {
    font-size: 12px;
}

.style-element-heading:hover {
    background-color: rgba(0,0,0,0.1);
}

.style-element-panel .form-section-heading {
    color: rgba(255,255,255,0.4);
    transition: all .3s;
    border-bottom: solid 1px rgba(255,255,255,0.2);
    cursor: pointer;
    display: flex;
}

.form-section-heading.with-arrow {
    padding-left: 24px;
}

.dropdown-arrow {
    font-size: 20px;
    display: inline-flex;
    position: absolute;
    left: 0px;
    top: 6px;
}

.style-element-panel:hover .form-section-heading {
    color: rgba(255,255,255,0.8);
}

.style-element-panel.has-value .form-section-heading {
    color: white;
    border-top: solid 1px rgba(255,255,255,0.2);
    border-bottom: solid 1px rgba(255,255,255,0.2);
    background-color: rgba(255,255,255,0.05);
}

.style-element-panel.selected .form-section-heading {
    color: var(--primary-color);
}

.style-option {
    border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    padding: 8px 10px;
    transition: all .3s;
}

.style-option--select {
    cursor: pointer;
}

.style-option--select:hover .style-class,
.style-class:hover {
    background-color: var(--primary-color);
}

.style-option:last-child {
}


.style-class {
    padding: 2px 6px;
    background-color: rgba(255,255,255,.1);
    border-radius: 2px;
    display: inline;
    text-align: center;
    cursor: pointer;
    transition: all .1s;
}

.style-class + .style-class {
    margin-left: 2px;
}

.style-option--classes {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1;
}


.style-option--classes .style-class {
    margin-bottom: 4px;
    margin-right: 4px;
    margin-left: 0;
}

.style-select-menu {
    max-height: 200px;
    overflow: hidden auto;
    font-size: 12px;
}

.style-select {
    padding: 4px 8px;
    cursor: pointer;
    transition: all .3s;
}

.style-select:hover {
    background-color: rgba(0,0,0,0.1);
}

.style-element-panel {
    position: relative;
}

.style-element-panel .MuiSwitch-root {
    transform: scale(0.6) translateY(-6px) translateX(10px);
    position: absolute;
    right: 0;
    top: 0;
}
.console-login {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
}

.login-splash {
    width: 50%;
}

.login-content {
    padding: var(--size-12);
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.login-content .form-layout {
    width: 100%;
    max-width: 400px;
    height: auto;
    background: none;
}
.joynt-tablet .login-content .form-layout,
.joynt-desktop .login-content .form-layout {
    width: 400px;
}

.login-form {
    width: 100%;
}

.form-status.form-status--error {
    padding: var(--size-5) var(--size-8);
    background-color: var(--danger-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
}

.joynt-mobile .login-splash {
    display: none;
}

.accordion__wrapper {
    opacity: 0.5;
    transition: all 0.3s;
    border-radius: var(--border-radius);
    background-color: rgba(var(--primary-color-rgb), 0);
}

.accordion__title.MuiAccordionSummary-root,
.accordion__title.MuiAccordionSummary-root.Mui-expanded {
    min-height: unset;
}


.accordion__wrapper:hover, .accordion--open {
    opacity: 1;
    background-color: rgba(var(--primary-color-rgb), 0.05);
}

.MuiAccordion-root.accordion {
    background: none;
    box-shadow: none;
    padding: var(--size-4) var(--size-3);
}

.MuiAccordionSummary-root.accordion__title {
    font-size: var(--font-size-lead);
    padding: 0;
}

.accordion__title .MuiAccordionSummary-content {
    font-size: inherit;
    margin: 0;
    line-height: 1.5em;
}

.accordion__title .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0;
    margin-bottom: var(--size-3);
}

.accordion__title-icon {
    margin-right: var(--size-5);
    transform: translateY(0.21em);
}

.MuiAccordionDetails-root.accordion__content {
    padding: 0;
    line-height: 1.5em;
    margin-left: var(--size-7);
    color: var(--text-color-light);
    font-size: var(--font-size-paragraph);
}
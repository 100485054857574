.media-browser .upload-area,
.media-browser .upload-area > div {
    min-height: 600px;
    overflow: visible;
}

.media-browser {
    font-size: 12px;
    min-width: 720px;
    max-height: 100vh;
}

.media-browser-sidebar {
    width: 260px;
    padding: 0.8rem 0;
}

.media-divider {
    padding: 16px 24px 8px 24px;
    font-weight: bold;
}

.media-library {
    padding: 1rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all .3s;
}

.media-library > * + * {
    margin-left: 1rem;
}

.media-library:hover, .media-library.active {
    background-color: var(--default-color);
}

.media-directories {
    margin-top: 0.4rem;
    margin-bottom: 1rem;
}

.media-directory {
    font-size: .8rem;
    padding: .4rem 1rem .4rem 2.4rem;
    cursor: pointer;
    transition: all .3s;
}

.media-directory > * + * {
    margin-left: 10px;
}

.media-directory:hover, .media-directory.active {
    background-color: rgba(255,255,255,0.1);
}
.booking-search {
    position: sticky;
    top: 0;
    z-index: 1;
}

.date {
    font-size: 22px;
}

.date-value {

}

.date-primary {
    font-size: 22px;
}

.date-secondary {
    font-size: 12px;
    opacity: 0.6;
}

.guests {
    font-size: 22px;
}

.booking-search-field {
    background-color: rgba(255,255,255,0.1);
    border-radius: 2px;
    padding: 8px 24px;
    cursor: pointer;
    transition: all .3s;
}

.booking-search-field:hover {
    background-color: rgba(255,255,255,0.2);
}

.booking-search-field .material-icons {
    opacity: 0.5;
}
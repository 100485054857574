.notification {
    cursor: pointer;
    transition: all .3s;
    background-color: var(--primary-lightest);
}

.notifications-list.notifications-list {
    max-height: none;
}

.notification.was-seen {
    background-color: transparent;
}

.notification:hover {
    background-color: var(--primary-lighter)
}

.notification {
    font-size: 12px;
}

.notifications-aggregated {
}

.notification-origin {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    top: .8rem;
    right: .8rem;
}

.notification-origin > * + * {
    margin-left: 6px;
}

.notifications-expand {
    padding-bottom: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    transition: background-color .3s;
}

.notifications-expand-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-lightest);
    padding: 6px 16px;
    border-radius: 16px;
}

.notifications-expand-btn .count {
    font-weight: bold;
    margin-right: 6px;
}

.notifications-expand > * + * {
    margin-left: 4px;
}

.notification-group.is-new .notifications-expand-btn {
    background-color: rgba(var(--success-color-rgb), 0.25);
}

.notification-group {
    border-bottom: solid 1px var(--primary-lighter);
    position: relative;
}

.notification-group.is-new {
    background-color: rgba(var(--success-color-rgb), 0.15);
}

.notification-group.is-new > .notification {
    background-color: transparent;
}

.notification-group > .notification {
    padding-right: 48px;
}

.workspace-avatar {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.post-text p:first-child {
    margin-top: 0;
}

.post-text,
.post-text .post-text {
    font-size: 14px;
    line-height: 1.6em;
    word-break: break-word;
}

.post-text > * + *,
.post-text .post-text > * + *,
.post-text .ql-editor > * + * {
    margin-top: 0.8em;
}

.post-text .ql-tooltip {
    z-index: 999;
}

.post-text .ql-editor.ql-editor {
    font-size: inherit;
    line-height: inherit;
}

.post-text ol,
.post-text ul {
    padding-left: 3em;
}

.post-text:not(.quill) br:first-child:last-child {
    display: none !important;
}

.post-text p > span, .post-text p > strong {
    color: inherit !important;
}

.post-text p > a {
    color: var(--link-color) !important;
    text-decoration: none;
}

.post-text p > a:hover {
    color: var(--link-color-dark) !important;
    text-decoration: underline;
}

.post-text ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0;
}

.post-text ol li {
    list-style-type: none;
}

.post-text .ql-indent-1 {
    margin-left: 1em;
}

.post-text .ql-indent-2 {
    margin-left: 2em;
}

.post-text .ql-indent-3 {
    margin-left: 3em;
}

.post-text .ql-indent-4 {
    margin-left: 4em;
}

.post-text .ql-indent-5 {
    margin-left: 5em;
}

.post-text .ql-indent-6 {
    margin-left: 6em;
}

.post-text .ql-indent-7 {
    margin-left: 7em;
}

.post-text .ql-indent-8 {
    margin-left: 8em;
}

.post-text ol li:before {
    content: counter(list-0, decimal) '. ';
    margin-left: -1.2em;
    margin-right: 0.35em;
}
.post-text ol li.ql-indent-1 {
    counter-increment: list-1;
}
.post-text ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ';
}
.post-text ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.post-text ol li.ql-indent-2 {
    counter-increment: list-2;
}
.post-text ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ';
}
.post-text ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.post-text ol li.ql-indent-3 {
    counter-increment: list-3;
}
.post-text ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ';
}
.post-text ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
.post-text ol li.ql-indent-4 {
    counter-increment: list-4;
}
.post-text ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ';
}
.post-text ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}
.post-text ol li.ql-indent-5 {
    counter-increment: list-5;
}
.post-text ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ';
}
.post-text ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9;
}
.post-text ol li.ql-indent-6 {
    counter-increment: list-6;
}
.post-text ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ';
}
.post-text ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9;
}
.post-text ol li.ql-indent-7 {
    counter-increment: list-7;
}
.post-text ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ';
}
.post-text ol li.ql-indent-7 {
    counter-reset: list-8 list-9;
}
.post-text ol li.ql-indent-8 {
    counter-increment: list-8;
}
.post-text ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ';
}
.post-text ol li.ql-indent-8 {
    counter-reset: list-9;
}
.post-text ol li.ql-indent-9 {
    counter-increment: list-9;
}
.post-text ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ';
}
.video-js .vjs-big-play-button {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border: none;
    background-color: var(--primary-dark-transparent);
}
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button:focus {
    background-color: var(--primary-color);
}

.video-js .vjs-control-bar {
    background-color: var(--primary-dark-transparent);
}
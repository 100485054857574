.timeline {
    margin-bottom: 3.2rem;
    position: relative;
    padding-top: 30px;
}

.timeline-track {
    position: relative;
    height: 40px;
    margin-bottom: 1px;
}

.timeline-event {
    position: absolute;
    top: 0;
    bottom: 0;
    color: white;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-size: 11px;
    overflow: hidden;
    border-radius: 60px;
    cursor: pointer;
}

.timeline-event:hover {
    box-shadow: inset 0 0 1px 1px var(--fg-color);
}

.timeline-event-name {
    overflow: hidden;
    white-space: nowrap;
    max-width: 60%;
    text-overflow: ellipsis;
    margin-left: 8px;
    color: var(--text-color);
}

.timeline-event-bg {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.3;
    z-index: -1;
}

.timeline-scale {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.timeline-scale-marker {
    position: relative;
    float: left;
    height: 100%;
    padding-left: 6px;
    font-size: 12px;
    opacity: 0.4;
}

.timeline-scale-marker:before {
    content: '';
    border-left: solid 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    opacity: 0.2;
}
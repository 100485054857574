.select-inline {
    display: inline-flex;
    cursor: pointer;
    transition: all .3s;
    align-items: center;
}

.select-inline:hover {
    outline: solid 1px rgba(255, 255, 255, 0.2);
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
}

.select-inline__arrow {
    margin-left: 0.4em;
    display: flex;
    align-items: center;
    opacity: 0.5;
}
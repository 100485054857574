@import "./views-mobile.css";

.workspace-overview__feed {
    margin: 0 auto;
}

.workspace-overview__header {
    grid-row: 1;
    grid-column: 1 / span 2;
}

.workspace-overview__about {
    grid-column: 2;
    grid-row: 2 / span 2;
    padding-top: 40px;
}

.workspace-overview__about .card {
    padding: var(--rem-size-6);
}

.workspace-overview__layout {
    display: grid;
    grid-template-columns: 580px 300px;
    grid-gap: var(--rem-size-6);
    overflow: hidden auto;
    width: 100%;

    /** FIXME **/
    padding-left: calc(50vw - 165px - 42px - 290px - 150px - 9px - 12px);
}

.joynt-desktop:not(.joynt-large-desktop) .workspace-overview__layout {
    grid-template-columns: 1fr 1fr;
}

.joynt-tablet .workspace-overview__layout {
    grid-template-columns: 1.4fr 1fr;
}

.workspace-overview__feed-header > * + * {
    margin-top: var(--rem-size-6);
}
.dark-mode .room-schedule {
    background-color: rgba(var(--primary-dark-2-rgb), 0.15);
}
.calls-widget {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: 4px;
    background-color: var(--default-color);
    min-width: 400px;
    overflow: hidden;
    box-shadow: 0 4px 20px 20px rgba(0,0,0,0.3);
    z-index: 4;
}

.call-info {
    padding: 2rem;
}

.call-window .video {
    position: relative;
    background-color: black;
}

.call-btn {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    border-radius: 9999px;
    box-shadow: 0 2px 20px 20px rgba(0,0,0,0.1);
    cursor: pointer;
}

#local-media-stream {
    position: absolute;
    width: 160px;
    height: 100px;
    top: 10px;
    left: 10px;
    object-fit: cover;
    border: solid 1px black;
}

#remote-media-stream {
    max-width: 400px;
}
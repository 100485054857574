.tab-link {
    font-weight: 400;
    opacity: 0.5;
    text-transform: none;
    font-size: 20px;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.tab-link__icon {
    font-size: 1.2rem;
}

.tab-link > * + * {
    margin-left: 0.4em;
}

.tab-link:hover, .tab-link.active {
    color: var(--primary-color);
}

.primary .tab-link:hover, .primary .tab-link.active {
    color: white;
}

.tab-link.active {
    opacity: 1;
}
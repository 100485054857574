.upload-area > div {
    width: 100%;
    /*overflow: hidden;*/
}

.drop-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: var(--invert-color);
    z-index: 1;
}

.drop-overlay:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--default-color);
    opacity: 0.8;
}

.drop-overlay > * + * {
    margin-top: 1rem;
}
.icon-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.icon-button__label {
    font-size: 11px;
    opacity: 0.5;
    margin-top: -4px;
    cursor: pointer;
    transition: transform .3s, opacity .3s;
}

.secondary-sidebar .icon-button__label {
    transform: translateX(-10px);
    opacity: 0;
}

.secondary-sidebar--collapsed .icon-button__label {
    transform: translateX(0);
    opacity: 0.5;
}
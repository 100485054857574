.toolbar {
    left: 50%;
    transform: translate(-50%) scale(0);
    position: absolute;
    background: var(--primary-color);
    border-radius: 4px;
    z-index: 2;
    box-sizing: border-box;
    padding: 0 5px;
}

.toolbar:after, .toolbar:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.toolbar:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: var(--primary-color);
    border-width: 4px;
    margin-left: -4px;
}

.toolbar:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: var(--primary-color);
    border-width: 6px;
    margin-left: -6px;
}
.search-input {
    background-color: rgba(0,0,0,0.05);
    border-radius: 3px;
    padding-left: 1rem;
    transition: background-color .3s;
    min-height: 48px;
    flex: 1;
}

.search-input__icon {
    transition: all .3s;
    opacity: 0.5;
    margin-right: 0.4rem;
}

.search-input:hover {
    background-color: rgba(0,0,0,0.08);
}
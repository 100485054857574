@media only screen and (min-width: 801px) {
    .console-user-menu {
        position: fixed;
        top: 0;
        left: 66px;
        box-shadow: 0 0 20px rgba(0,0,0,0.4);
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        transition: all .3s;
        transform: translateX(-100%);
        min-width: 590px;
        max-height: 100vh;
        height: 100vh;
        overflow: hidden;
        display: flex;
    }

    .console-user-menu.is-visible {
        opacity: 1;
        transform: translateX(0);
        pointer-events: all;
    }

    .user-menu-drawer {
        min-width: 600px;
    }
}

@media only screen and (max-width: 800px) {
    .user-menu-drawer {
        background-color: transparent !important;
        width: 100%;
    }
    .console-user-menu { display: none; }
    .console-user-menu.is-visible {
        display: flex;
    }
    .console-user-menu {
        position: fixed;
        top: 0;
        bottom: 56px;
        left: 0;
        right: 0;
    }
}
.list-item {
    transition: all .3s;
}

.list-item > * {
    transition: all .3s;
}

.list-item.is-dragging {
    transform: scale(0.82);
    background-color: rgba(255,255,255,0.1);
    border-radius: 4px;
}

.list-item.is-dragging > * {
    opacity: 0;
}

.list-item-title-placeholder {
    font-style: italic;
    opacity: 0.3;
}

.list-item-avatar.active {
    color: var(--fg-color);
    background-color: var(--bg-color);
}

.list-item .secondary-action-button {
    opacity: 1;
    transition: all .3s;
}

.list-item:hover .secondary-action-button {
    opacity: 1;
}
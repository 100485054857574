.chat {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--component-bg);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 var(--size-5);
    border-radius: var(--border-radius);
}

.joynt-mobile .chat {
    overflow: visible;
}

.joynt-mobile .conversation-view {
    z-index: 3;
}

.joynt-mobile .show-sidebar .conversation-view,
.joynt-mobile .show-sec-sidebar .conversation-view {
    z-index: unset;
}


.chat .upload-area > div {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex-grow: 1;
}

.joynt-mobile .chat .upload-area > div {
    overflow: visible;
}


.message-list {
    padding: 0.8rem 0.8rem 0 0.8rem;
    /** Hack safari issues **/
    flex-basis: 1px !important;
}


.threads {
    width: 400px;
    border-right: solid 1px;
    border-color: var(--light-color);
}

/*.threads-header, .chat-header {*/
/*    position: sticky;*/
/*    top:0;*/
/*    background-color: white;*/
/*    z-index: 1;*/
/*}*/

.thread {
    padding: 0.8rem;
    display: flex;
    transition: all .3s;
    cursor: pointer;
    border-radius: var(--border-radius);
    font-size: 12px;
    align-items: flex-start;
    flex-shrink: 0;
}

.thread:hover {
    background-color: var(--primary-lightest);
}

.thread.active {
    background: var(--primary-lighter);
}

.thread__content {
    max-width: 400px;
    margin-left: 16px;
    position: relative;
}

.thread__content > * + * {
    margin-top: 3px;
}

.thread__subject {
    font-weight: 600;
}

.thread__text {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 6px;
}

.thread__signature {
    font-size: 11px;
}

.thread__signature > * {
    display: inline;
}

.thread__time {
    opacity: 0.5;
    font-size: 11px;
    white-space: nowrap;
}

.thread__badge {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(4px) translateY(-4px);
}

.thread__unread {
    opacity: 0;
    display: flex;
    align-items: center;
    margin-right: 8px;
}

.thread__unread:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 999px;
    background-color: var(--success-color);
}

.unread .thread__unread {
    opacity: 1;
}

.message-skeleton {
    width: 280px;
    height: 40px;
}

.thread-skeleton {
    padding: 0.8rem;
    padding-left: 40px;
}

.thread-skeleton svg {
    width: 240px;
    height: 47px;
}

.message-skeleton + .message-skeleton {
    margin-top: 1rem;
}

.messages-list {
    min-height: 100%;
}
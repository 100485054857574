.form-section-heading {
    font-weight: 600;
    padding: 8px 0 16px 0;
    font-size: 14px;
    cursor: pointer;
}

.form-compact .form-section-heading {
    border-bottom: solid 1px rgba(255,255,255,0.1);
    margin-left: -5px;
    margin-right: -5px;
    padding-top: 0;
    cursor: pointer;
    color: rgba(255,255,255,0.5);
    margin-top: 0.6rem;
}

.form-compact .form-section .fields {
    margin: 8px 0 1rem 0;
}
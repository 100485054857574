.tasks-list {
    width: 100%;
}

.tasks-list > * + * {
    border-top: solid 1px rgba(0,0,0,0.1);
}

.task {
    padding: 4px .8rem;
    cursor: pointer;
    transition: all .3s;
    font-size: 12px;
    min-height: 40px;
}

.task:hover {
    background-color: rgba(0,0,0,0.05);
}

.task--completed .task__description {
    text-decoration: line-through;
    opacity: 0.5;
}

.create-task {
    padding: 0 50px;
    height: 40px;
    align-items: center;
    font-size: 12px;
}
.guest-types-select {
    min-width: 320px;
}

.stepper-button {
    min-width: 0 !important;
}

.stepper-value {
    min-width: 48px;
    text-align: center;
    font-size: 22px;
}

.guest-room-label {
    background-color: rgba(0,0,0,0.05);
    min-height: 40px;
    padding-left: 0.8rem;
}
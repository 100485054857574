.joynt-mobile .workspace-overview__layout  {
    display: flex;
    flex-flow: column;
    padding: var(--size-4);
}

.joynt-mobile .workspace-overview__about {
    padding-top: 0;
    order: 2;
}

.joynt-mobile .workspace-overview__header {
    order: 1;
}

.joynt-mobile .workspace-overview__feed-header {
    order: 3;
}

.joynt-mobile .workspace-overview__layout .feed-list {
    order: 4;
}
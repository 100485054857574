.ui-icon-btn-group {
    display: flex;
}

.ui-icon-btn-group > * + * {
    margin-left: 0.2rem;
}

.ui-icon-btn {
    opacity: 0.4;
    transition: all .3s;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 18px;
}

.ui-icon-btn:hover {
    opacity: 0.8;
}

.ui-icon-btn.checked {
    color: var(--primary-color);
    opacity: 1;
}
.page {
    flex: 1;
    background-color: white;
    padding: var(--size-10) 0;
    margin: 0 calc(0px - var(--layout-padding));
    border-right: solid 1px var(--primary-lightest);
}

.page .editor-canvas {
    padding: 0;
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
    border: none;
}
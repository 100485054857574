.phone-app-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: var(--primary-color);
    color: white;
    height: 66px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    padding: 0 0.8rem;
}


.joynt-mobile .lock-scroll {
    overflow: hidden !important;
    position: fixed !important;
}

.joynt-mobile input[type="text"], input[type="password"], [contenteditable] {
    font-size: 16px;
}


.joynt-desktop .phone-app-bar {
    display: none;
}

.typing-dot {
    height: 6px;
    width: 6px;
    border-radius: 100%;
    margin-left: 4px;
    display: inline-block;
    background-color: var(--primary-light);
    animation: 1.2s typing-dot ease-in-out infinite;
}
.typing-dot:nth-of-type(2) {
    animation-delay: 0.15s;
}
.typing-dot:nth-of-type(3) {
    animation-delay: 0.25s;
}
@keyframes typing-dot {
    15% {
        transform: translateY(-35%);
        opacity: 0.5;
    }
    30% {
        transform: translateY(0%);
        opacity: 1;
    }
}
.rewire-mapping {
    display: flex;
    flex: 1;
}

.rewire-pointer {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
    font-size: 11px;
    color: rgba(0,0,0,0.5);
    padding: 6px 10px;
}
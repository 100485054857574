.identity-avatar {
    border-radius: 999px;
    overflow: hidden;
    width: 28px;
    height: 28px;
    border: solid 2px rgba(255,255,255,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.identity-avatar.generic {
    width: 28px !important;
    height: 28px !important;
    font-size: 10px !important;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
}

.identity-avatar-md .identity-avatar.generic {
    width: 40px !important;
    height: 40px !important;
}

.identity-cluster {
    display: flex;
}

.identity-cluster-sm > *:nth-child(1n+2) {
    margin-left: -10px;
}

.identity-cluster-md {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .8rem;
}

.tile-identity {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.identity-avatar-md {
    width: 40px;
    height: 40px;
}

.identity-avatar-name {
    margin-top: .4rem;
    font-size: 12px;
    text-align: center;
}
.resource-item {
    cursor: pointer;
}

.resource-item.active .resource-item-icon {
    color: white;
    background-color: var(--primary-color);
}

.resource-item:hover {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
}

.resource-item-label {
    font-size: 12px;
    margin-top: 8px;
    text-align: center;
    max-width:80px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
}

.resource-item-icon {
    background-color: rgba(255,255,255,0.05);
    border-radius: 999px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
    font-size: 1.4rem;
}
.cms-theme .select-chip {
    background-color: rgba(0,0,0,0.05);
    border-radius: 12px;
}

.cms-theme .chip-label {
    padding: 6px 0 6px 12px;
}

.cms-theme .chip-delete {
    padding: 0 12px;
}

.cms-theme .select-tags .drop-target {
    padding: 0;
}

.cms-theme .ql-snow .ql-editor {
    max-height: 140px;
}

.cms-theme .ql-container.is-fullscreen .ql-editor {
    max-height: none;
}

.cms-theme .ql-toolbar {
    position: relative;
}

.cms-theme .ql-toolbar .ql-fullscreen {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.05);
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    float: none;
}

.cms-theme .ql-toolbar .ql-fullscreen svg {
    width: 18px;
    height: 18px;
}

.cms-theme .ql-toolbar .ql-fullscreen svg * {
    stroke: currentColor;
}

.cms-theme .description-field {
    width: 200px;
    border-bottom: dotted 2px rgba(0,0,0,0.1);
    line-height: 1.8em;
}

.cms-theme .media-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: 100px;
    gap: 0.6rem;
}

.cms-theme .MuiSwitch-root + .MuiFormControlLabel-label {
    font-size: 12px;
}
.files-list {
    display: flex;
    gap: var(--size-2);
    flex-flow: row wrap;
}

.file-item {
    --preview-size: 48px;
    --file-item-bg: var(--primary-lightest);

    background-color: var(--file-item-bg);
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    position: relative;
}

.file-item__preview {
    height: var(--preview-size);
    width: var(--preview-size);
    min-width: var(--preview-size);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-lightest);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
}

.file-item__preview .media-preview {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.file-item__name {
    font-size: 12px;
    padding: 1em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40px;
    word-break: break-all;
}
.ui-color-swatch {
    width: 28px;
    height: 28px;
    border-radius: 999px;
    border: solid 2px transparent;
    margin: 4px;
    transition: all .3s;
    transform-origin: center center;
    cursor: pointer;
}

.ui-color-swatch:hover {
    transform: scale(1.2);
}

.ui-color-value {
    width: 36px;
    height: 36px;
    border-radius: 8px;
}
.value-placeholder.value-placeholder {
    font-style: italic;
    opacity: 0.4;
    transition: opacity .3s;
    color: inherit;
}

.value-placeholder:hover {
    opacity: 0.4;
}

[contenteditable]:empty:before {
    content: attr(data-placeholder);
    opacity: 0.3;
    font-style: italic;
    color: inherit;
    display: inline-block;
    cursor: text;
}

.inline-text-edit.focused {
}

.input-dense {
    background-color: rgba(255,255,255,0.05);
    border-radius: 4px;
    padding: 0 10px;
    min-height: 30px;
    font-size: inherit !important;
}

.input-dense:hover {
    background-color: rgba(255,255,255,0.1);
}

.theme-light .input-dense {
    background-color: rgba(0,0,0,0.1);
    color: var(--dark-color);
}

.theme-light .input-dense:hover {
    background-color: rgba(0,0,0,0.2);
}

.input-dense--select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.input-dense__value {
    flex: 1;
    display: flex;
    align-items: center;
}

.input-dense.is-empty .input-dense__value {
    font-style: italic;
    opacity: 0.5;
}

.input-dense__label {
    font-size: 10px;
    opacity: 0.5;
    line-height: 1.4em;
}
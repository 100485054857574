.backdrop {
    display: none;
}

.joynt-mobile .cols.desktop-sm\@rows,
.joynt-tablet .cols.desktop-sm\@rows {
    flex-direction: column;
}

 .joynt-mobile .cols.cols.desktop-sm\@rows:not(.strict) > * + *,
 .joynt-tablet .cols.cols.desktop-sm\@rows:not(.strict) > * + * {
    margin-left: 0;
    margin-top: var(--gap);
}

.joynt-mobile .cols.desktop-sm\@rows.cols-2 > *,
.joynt-tablet .cols.desktop-sm\@rows.cols-2 > *{
    flex-basis: auto;
}

.joynt-mobile.joynt-theme .main {
    padding: 0;
}

.joynt-theme.joynt-mobile .login-content:after,
.joynt-theme.joynt-mobile .login-content:before {
    opacity: 0.2;
}

.joynt-mobile .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    z-index: 2;
    opacity: 0;
    pointer-events: none;
    transition: all .3s;
}

.joynt-mobile .spaces-grid {
    display: flex;
    flex-direction: column;
}

.joynt-mobile .spaces-grid > * + * {
    margin-top: 10px;
}

.joynt-mobile .tile {
    flex-shrink: 0;
}

.joynt-mobile .inbox-chat .chat-header,
.joynt-mobile .conversation-view .chat-header {
    display: none;
}

.questions-list.gap-md > * {
    --gap: 1.2rem;
}

.question__back-button-wrapper {
}

.question {
    overflow: hidden;
}

.question > * + * {
    margin-top: 12px;
}

.question__identity {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.question__author {
    font-size: 0.81rem;
    font-weight: bold;
}

.question__content-wrapper {
    padding: 12px 12px 4px;
    border-radius: var(--border-radius);
}

.question .question__content-wrapper {
    background: var(--component-bg);
}

.question .question__reaction {
    background-color: transparent;
}

.question__content {
    font-size: 0.8rem;
    line-height: 1.6em;
}

.question__footer {
    display: flex;
    margin-top: 4px;
    justify-content: space-between;
}

.question__footer .MuiIconButton-root {
    opacity: 0.5;
}

.question__reaction {
    opacity: 0.5;
}

.question__timestamp {
    font-size: 11px;
    opacity: 0.5;
}

.question__reaction + .question__reaction {
    margin-left: 16px;
}

.question__reaction:hover {
    opacity: 1;
}

.question__reaction--voted, .question__reaction--replies {
    opacity: 1;
}

.question__back-button-wrapper {
    display: flex;
}

.question__button-counter {
    margin-left: 12px;
}

.question-replies {
    gap: var(--size-5);
}

.question-replies .message-list {
    padding-bottom: 60px;
}

.question-replies .MuiButton-text {
    padding: 0;
}

.question-reply {
}

.question-reply__content {
    border-radius: var(--border-radius);
    background-color: var(--primary-light);
}
.sidebar-tutorial {
    font-size: 14px;
    line-height: 1.6em;
    padding: var(--rem-size-6);
    position: relative;
    flex-grow: 1;
}

.sidebar-tutorial:after {
    content: '';
    background-image: url('https://joynt.works/thumb?file=joynt-website%2Fuser%2Fapp%2Fno-access.svg&w=200&h=150#200,150');
    background-position: 0 110px;
    position: absolute;
    bottom: 0;
    left: 10px;
    right: 10px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: contain;
    opacity: 0.5;
}
.joynt-theme.theme-light {
    --bg-color: #f5f2f8;
    --text-color: #380F67;
    --text-color-rgb: 56, 15, 103;
    --text-color-light: rgba(56, 15, 103, 0.85);
    --primary-color: #4800bb;
    --primary-color-rgb: 72,0,187;
    --primary-light: #daccf1;
    --primary-lighter: #ece2f5;
    --primary-lightest: #f6f3fc;
    --primary-dark: rgb(40, 28, 60);
    --primary-dark-1: #351955;
    --primary-dark-1-rgb: 53, 25, 85;
    --primary-dark-2: #5F32A5;
    --primary-dark-2-rgb: 95, 50, 165;
    --primary-dark-rgb: 40, 28, 60;
    /*TODO: replace --primary-dark value with --primary-dark-true, as this is the right one for joynt theme*/
    --primary-dark-true: #281C3C;
    --primary-dark-true-rgb: 40, 28, 60;
    --component-bg: #FFFFFF;

    --primary-dark-transparent: rgba(42,20,109,0.9);
    --primary-dark-transparent-flat: #2f1f4c;
    --primary-text: #380F67;
    --shadow-color: rgba(42,20,109, 0.05);
    --dark-shadow-color: rgba(42,20,109, 0.15);
    --border-radius: 10px;
    --border-radius-small: 5px;
    --theme-pink: #ff7bac;
    --theme-pink-light: #F7B0CE;
    --theme-pink-rgb: 255, 123,172;
    --theme-deep-purple: #4915bc;
    --theme-teal: #38dbcd;
    --theme-green: #008351;
    --theme-green-rgb: 0, 131, 81;
    --theme-red: #BC4163;
    --theme-red-rgb: 188, 65, 99;
    --danger-color: #E02020;
    --light-color: #ece7f8;
    --scrollbar-color: var(--primary-light);
    --link-color: #1035ff;
    --link-color-dark: #0722a2;
    --link-color-rgb: 16, 53, 255;
    --active-color: var(--theme-teal);
    --sidebar-bg-color: white;
    --secondary-sidebar-bg-color: var(--sidebar-bg-color);
    --session-sidebar-bg-color: var(--sidebar-bg-color);
    --warning-color: #FD8343; /** #cddc39; **/
    --high-warning-color: #ff5722;
    --success-color: var(--theme-pink);
    --success-color-rgb: 255,123,172;
    --body-font-family: 'Roboto';
    --overlay-bg-color: #48346a;
    --mentions-background-color: white;
    color: var(--text-color);
}

.joynt-theme.theme-light {
    --primary-color: #5F32A5;
    --primary-color-rgb: 95, 50, 165;
    --primary-light: rgba(var(--primary-color-rgb), 0.2);
    --primary-lighter: rgba(var(--primary-color-rgb), 0.1);
    --primary-lightest: rgba(var(--primary-color-rgb), 0.05);
    --bg-color: rgba(28,20,41,0.05);
    --sidebar-bg-color: var(--bg-color);
    --secondary-sidebar-bg-color: #e8e8ea;
    --session-sidebar-bg-color: white;
}

.text-danger {
    color: var(--high-warning-color);
}

.link {
    color: var(--link-color);
    text-decoration: none;
}

.link:hover {
    color: var(--link-color-dark);
    text-decoration: underline;
}

.link-text {
    cursor: pointer;
}

.link-text:hover {
    color: var(--link-color);
    text-decoration: underline;
}

.joynt-theme.theme-light .default {
    --fg-color: var(--text-color);
}

.joynt-theme .light {
    background-color: var(--light-color);
}

.joynt-theme .pink {
    --fg-color: var(--theme-pink) !important;
    color: var(--theme-pink) !important;
}

.joynt-theme .bg-pink {
    background-color: var(--theme-pink) !important;
}

.joynt-theme .deep-purple {
    --fg-color: var(--theme-deep-purple) !important;
    color: var(--theme-deep-purple) !important;
}

.joynt-theme .bg-deep-purple {
    background-color: var(--theme-deep-purple) !important;
}

.joynt-theme .teal {
    --fg-color: var(--theme-teal) !important;
    color: var(--theme-teal) !important;
}

.joynt-theme .bg-teal {
    background-color: var(--theme-teal) !important;
}

.joynt-theme .text-success {
    --fg-color: var(--theme-pink) !important;
    color: var(--fg-color);
}


.joynt-theme .aside {
    background-color: var(--primary-color);
    color: white;
}

.channel-tabs {
    padding: 0;
}

.joynt-theme .messages,
.joynt-theme .chat-view .chat,
.joynt-theme .space-view .chat {
    background-color: white;
    box-shadow: 0 0 20px var(--shadow-color);
    border-radius: 4px;
    padding: 0;
    overflow: hidden;
}


.joynt-mobile.joynt-theme .chat-view .chat {
    overflow: visible;
}

.joynt-theme .sidebar,
.joynt-theme .secondary-sidebar {
    border-right: none;
    border-left: none;
}

.joynt-theme .channel-tabs {
    border-bottom: none;
}

.joynt-theme .channel.is-active {
    background-color: var(--primary-light);
}

.joynt-theme .sidebar .channel.is-active {
    margin: 8px 0;
}

.joynt-theme .sidebar .channel.is-active:first-child {
    margin-top: 0;
}

.joynt-theme .tile {
    background-color: white;
    box-shadow: 0 10px 20px var(--shadow-color);
}

.joynt-theme .sidebar-section {
    background-color: var(--sidebar-bg-color);
}

.joynt-theme .secondary-sidebar {
    --bg-color: var(--secondary-sidebar-bg-color);
    background-color: var(--bg-color);
}

.joynt-theme .main-stage-view.is-collapsed .secondary-sidebar,
.joynt-theme.active-session .secondary-sidebar {
    background-color: var(--session-sidebar-bg-color);
}

.joynt-theme .sidebar-section,
.joynt-theme .secondary-sidebar {
    border-radius: var(--border-radius);
}

.joynt-theme .messages + .secondary-sidebar {
    display: none;
}

.joynt-theme .btn-outline {
    min-width: 6em;
    border-radius: 999px;
}

.joynt-theme .btn-outline > span {
    border-radius: 999px;
    padding: 0.4rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;

}

.joynt-theme .btn-outline.primary {
    --bg-color: var(--text-color) !important;
}

.joynt-theme .brand-bg {
    position: relative;
}

.joynt-theme .brand-bg > * {
    z-index: 1;
}

.joynt-theme .brand-bg:before {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/stage-1.svg');
    background-repeat: no-repeat;
    width: 260px;
    height: 260px;
    position: absolute;
    right: -80px;
    top: 40px;
    opacity: 0.1;
}

.joynt-theme .brand-bg:after {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/stage-2.svg');
    background-repeat: no-repeat;
    width: 260px;
    height: 260px;
    position: absolute;
    left: -80px;
    bottom: 120px;
    opacity: 0.1;
}

.joynt-theme .dark-mode .brand-bg:after {
    opacity: 0.6;
}

.joynt-theme .brand-bg-alt:before {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/illustrations/Planet_background.svg');
    background-repeat: no-repeat;
    width: 260px;
    height: 260px;
    position: absolute;
    right: -64px;
    top: -64px;
    pointer-events: none;
}

.joynt-theme .brand-bg-alt:after {
    content: '';
    background-size: contain;
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/teal-circle.svg');
    background-repeat: no-repeat;
    width: 45px;
    height: 45px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(-40%);
    pointer-events: none;
}

.joynt-theme .brand-bg--rev:before {
    left: -124px;
    top: -84px;
    bottom: auto;
    right: auto;
    transform: scale(-1);
    width: 300px;
    height: 300px;
}

.joynt-theme .brand-bg--rev:after {
    right: 0;
    left: auto;
    transform: translateY(-50%) translateX(40%);
}

.joynt-theme .brand-bg-alt {
    overflow: hidden;
}

.joynt-theme .space-view .brand-bg:before {
    display: none;
}

.joynt-theme .brand-no-access {
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/no-access.svg');
}

.joynt-theme .session-pending-illustration {
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/pending-session.svg');
}

.joynt-theme .polls-empty-state {
    background-image: url('https://cms.zuu.tools/thumb?file=joynt-website/user/app/polls-empty-state.svg');
}

.joynt-theme .search-input {
    background-color: var(--primary-lightest);
    border-radius: var(--border-radius);
}

.joynt-theme .bottom-nav {
    border-top: solid 1px var(--primary-color);
}

.joynt-theme .MuiDialog-root .MuiPaper-rounded {
    border-radius: var(--border-radius);
}
.type-default {
    font-size: 14px;
    opacity: 0.9;
    line-height: 1.6em;
}

.type-heading {
    font-size: 24px;
}

.type-heading-sm {
    font-size: 20px;
    font-weight: bold;
}

.type-heading-xs {
    font-size: 18px;
    font-weight: bold;
}

.type-heading-xxs {
    font-size: 16px;
    font-weight: bold;
}

.type-strong {
    font-weight: bold;
}

.type-lg {
    font-size: 16px;
    line-height: 1.6em;
    max-width: 30em;
}

.type-sm {
    font-size: 12px;
    line-height: 1.4em;
}

.link {
    color: var(--success-color);
}

.fa.size-small {
    font-size: 16px;
}
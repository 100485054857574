.tags-input {
    background: transparent;
    border: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

.tags-input:focus {
    outline: none;
}
.media-preview {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.media-generic {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
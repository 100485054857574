/** FIXME **/

.session-nav-content .post {
    --post-title-fontSize: var(--font-size-h3-heading);
}

.session-nav-content .post-header__parent {
    display: none;
}

.session-nav-content .post-thread {
    display: none;
}

.session-nav-content .post-author {
    font-size: var(--font-size-small);
}

.session-nav-content .post-header .MuiIconButton-root {
    margin-top: -6px;
    margin-bottom: -6px;
}

.session-nav-content .post.gap-md > * {
    --gap: var(--rem-size-4);
}

.session-nav-content .files-list {
    grid-template-columns: repeat(1, 1fr);
}
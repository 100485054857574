.collection-list {
    width: 100%;
}

.collection-list li {
    max-width: none;
}

.collection-add {
    transition: all .3s;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding: 4px 8px;
    margin-top: 2px;
    cursor: pointer;
}

.collection-add:hover {
    background-color: var(--primary-color);
}
/**
    Styles that should probably end up in atoms framework
 */

.pad-smd {
    padding: 1.4rem;
}

.rows.reverse {
    flex-direction: column-reverse;
}

.bg-white {
    background-color: white;
}

.text-white {
    color: white !important;
}

.text-center {
    text-align: center;
}

.transparent {
    background-color: transparent !important;
    color: white !important;
}

.link {
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: underline;
    transition: all .2s;
}

.link > * + * {
    margin-left: 0.4em;
}

.link:hover {
    color: var(--success-color);
}

.grid-6 {
    grid-template-columns: repeat(6, 1fr);
}

.grid-8 {
    grid-template-columns: repeat(8, 1fr);
}

.span-2-cols {
    grid-column: 1 / span 2;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1;
}

.absolute-lt {
    position: absolute;
    top: 0;
    left: 0;
}

.absolute-b {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.circle {
    border-radius: 9999px;
}

.inline {
    display: inline-flex;
}

.inline > * + * {
    margin-left: 0.4em;
}

.cols.wrap {
    flex-wrap: wrap;
}

.cols.wrap.gap-sm > * {
    margin-left: 0 !important;
    margin-right: var(--gap);
}

.avatar-sm,
.MuiAvatar-root.avatar-sm {
    width: 24px;
    height: 24px;
}

.avatar-lg,
.MuiAvatar-root.avatar-lg {
    width: 64px;
    height: 64px;
}

.cols-2 > * {
    flex-basis: 50%;
    flex-grow: 1;
}

.cols-3 > * {
    flex-basis: 33.333%;
    flex-grow: 1;
}

.no-shrink {
    flex-shrink: 0;
}

.clickable {
    cursor: pointer;
}

.pad {
    padding: var(--padding);
}

@media (max-width: 800px) {

    .phone\@grid-8 {
        grid-template-columns: repeat(8, 1fr) !important;
    }

}

@media (max-width: 1400px) {
    .desktop-sm\@grid-4 {
        grid-template-columns: repeat(4, 1fr) !important;
    }
}

@media (min-width: 1600px) {
    .desktop-lg\@grid-3 {
        grid-template-columns: repeat(3, 1fr) !important;
    }
}
:root {
    --video-x: 0;
    --video-y: 0;
    --video-width: 0;
    --video-height: 0;
    --viewport-height: 100vh;
    --100vh: 100vh;
}

.joynt-mobile {
    --100vh: var(--viewport-height);
}

.meeting-overlay {
    position: fixed;
    transform-origin: bottom left;
    top: 0;
    left: 0;
    width: 320px;
    height: 240px;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1;
}

.meeting-overlay.fit {
    width: var(--video-width);
    height: var(--video-height);
    transform: translateX(var(--video-x)) translateY(var(--video-y));
}

.meeting-overlay.is-initialized {
    transition: all .3s;
}

body.dragging .meeting-overlay {
    transition: none;
}

.meeting-overlay.lock {
    height: var(--100vh);
    display: flex;
}

.meeting-view {
}

.meeting-grid {
    display: grid;
    grid-gap: 3px;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    height: 100%;
}

#root > .video-stage {
    height: var(--100vh);
}

.video-stage {
    background-color: var(--bg-color);
}

.video-stage.tiles-0 {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}

.meeting-grid.tiles-1 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 100%;
}

.meeting-grid.tiles-2 {
    grid-template-rows: 100%;
}

.meeting-grid.tiles-3 > :nth-child(3) {
    transform: translateX(50%);
}

.meeting-grid.tiles-2,
.meeting-grid.tiles-3,
.meeting-grid.tiles-4 {
    grid-template-columns: repeat(2, 1fr);
}

.meeting-grid.tiles-5,
.meeting-grid.tiles-6,
.meeting-grid.tiles-7,
.meeting-grid.tiles-8,
.meeting-grid.tiles-9{
    grid-template-columns: repeat(3, 1fr);
}

.joynt-mobile .meeting-grid.tiles-2 {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 50%;
}

.joynt-mobile .meeting-focused.tiles-3,
.joynt-mobile .meeting-focused.tiles-4,
.joynt-mobile .meeting-focused.tiles-5 {
    grid-template-rows: 70% auto;
}

.joynt-mobile .meeting-focused.tiles-3 {
    grid-template-columns: repeat(2, 1fr);
}

.joynt-mobile .meeting-focused.tiles-4 {
    grid-template-columns: repeat(3, 1fr);
}

.joynt-mobile .meeting-focused.tiles-5 {
    grid-template-columns: repeat(4, 1fr);
}

.joynt-mobile .meeting-focused .video-tile.focus {
    grid-column: 1 / span 3;
}

.joynt-mobile .meeting-focused.tiles-2 .video-tile:not(.focus) {
    bottom: 100px;
    top: auto;
}

.meeting-focused {
    height: 100%;

    --stage-grid-rows: 5;

    display: grid;
    grid-gap: 6px;

    grid-template-columns: auto minmax(120px, 10vw) minmax(120px, 10vw);
    grid-template-rows: repeat(var(--stage-grid-rows), 1fr);
}

.active-session .meeting-focused {
    grid-template-columns: 100% !important;
    --stage-grid-rows: 1;
}

.meeting-focused.tiles-1 {
    grid-template-columns: auto;
}

.meeting-focused.tiles-2 {
    grid-template-columns: 100%;
    --stage-grid-rows: 1;
}

.meeting-focused.tiles-2 .video-tile:not(.focus) {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 160px;
    height: 120px;
}

.meeting-focused.tiles-3 {
    --stage-grid-rows: 2;
}
.meeting-focused.tiles-4 {
    --stage-grid-rows: 3;
}

.meeting-focused.tiles-3,
.meeting-focused.tiles-4,
.meeting-focused.tiles-5 {
    grid-template-columns: auto minmax(160px, 16vw);
}

.meeting-focused.tiles-5 {
    --stage-grid-rows: 4;
}

.meeting-focused.tiles-6 {
    --stage-grid-rows: 5;
    grid-template-columns: auto minmax(120px, 12vw);
}
.meeting-focused.tiles-7 {
    --stage-grid-rows: 6;
    grid-template-columns: auto minmax(100px, 10vw);
}

.meeting-focused.tiles-8,
.meeting-focused.tiles-9 {
    --stage-grid-rows: 4;
}

.meeting-focused .video-tile {
    z-index: 2;
    border-radius: 6px;
}

.meeting-focused .focus {
    grid-column: 1;
    grid-row: 1 / span var(--stage-grid-rows);
}

.video-stage.is-minimized .video-tile:not(.focus) {
    /*display: none;*/
}

.video-tile {
    position: relative;
    overflow: hidden;
    background-color: black;
    border-radius: 6px;
}

.video-tile:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    border: solid 3px var(--theme-teal);
    opacity: 0;
    transition: all .3s;
    pointer-events: none;
}

.video-tile.speaking:before {
    opacity: 1;
}

.video-tile.own {
    background-color: var(--primary-dark);
}

.meeting-focused .video-tile.own {
    background-color: black;
}

.meeting-focused .video-tile.focus {
    background-color: var(--primary-dark);
}

.video-tile > .OT_publisher,
.video-tile > .OT_subscriber {
    width: 100% !important;
    height: 100% !important;
}

.video-tile.screen video {
    object-fit: contain !important;
}

.video-tile > .video-stream {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.video-tile > .stream-debug {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.video-tile > .video-tile-icons {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px;
    color: white;
    z-index: 2;
    display: none;
}

.tile-icon.tile-icon--hand {
    color: white;
    position: absolute;
    top: 8px;
    left: 8px;
    font-size: 12px;
    transition: transform .3s, opacity .3s;
    opacity: 0;
    transform: translateY(1rem);
}

.tile-icon.tile-icon--hand.on {
    color: white;
    background-color: var(--primary-color);
    opacity: 1;
    transform: translateY(0);
}

.debug .video-tile > .video-tile-icons {
    display: block;
}

.tile-avatar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;;
    align-items: center;
    color: rgba(255,255,255,0.5);
    font-size: 18px;
}

.tile-avatar > * + * {
    margin-top: 6px;
}

.tile-avatar__name {
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transform: translateY(10px);
    transition: all .3s;
}

.tile-avatar__image {
    opacity: 0;
    transform: scale(0.1);
    transition: all .3s;
}

.video-tile.no-video .tile-avatar__name {
    opacity: 1;
    transform: translateY(0);
}

.video-tile__name {
    opacity: 1;
    transition: all .3s;
    pointer-events: none;
}

.video-tile.no-video .video-tile__name {
    opacity: 0;
    transform: translateX(-10px);
}

.video-tile.no-video .tile-avatar__image {
    transform: scale(1);
    opacity: 1;
}

.meeting-focused .video-tile:not(.focus) .tile-info {
    padding: 6px;
}

.meeting-focused .video-tile:not(.focus) .tile-icon {
    width: 18px;
    height: 18px;
}

.meeting-focused .video-tile:not(.focus) .tile-avatar {
    font-size: 12px;
}

.meeting-focused .video-tile:not(.focus) .tile-avatar__image {
    width: 42px;
    height: 42px;
}

.joynt-mobile .video-stage {
    grid-gap: 0;
}

.tile-info {
    position: absolute;
    z-index: 2;
    bottom: 0;
    color: white;
    padding: 8px;
}

.joynt-mobile .tile-info {
    display: none;
}

.tile-icon {
    border-radius: 9999px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
}

.tile-icon.on {
    color: var(--theme-teal);
}

.tile-icon.off {
    background-color: var(--danger-color);
}

.meeting-video {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.meeting-video.is-local {
    grid-row: 2;
}

.meeting-video.is-pinned {
    grid-row: 1;
    grid-column-start: 1;
    grid-column-end: 4;
}

.meeting-video:first-child:last-child {
    grid-row: 1 / span 1;
    grid-column: 1 / span 3;
}

.meeting-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    transition: opacity .3s;
    opacity: 0;
}

.meeting-video.is-active video {
    opacity: 1;
}

.meeting-video-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.stage-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-dark-transparent);
    color: white;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stage-overlay__icon {
    background-color: white;
    color: var(--text-color);
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stage-overlay {
    opacity: 0;
    pointer-events: none;
}

.stage-overlay:hover {
    opacity: 1;
}

.stage-overlay-toolbar {
    transition: all .3s;
}

.stage-overlay:hover .stage-overlay-toolbar {
    transform: translateY(0);
}

.active-session .stage-overlay {
    pointer-events: all;
    cursor: pointer;
}

.main-stage-view.session-pending .header {
    transform: translateY(-50%);
    opacity: 0;
}

.main-stage-view.session-pending .secondary-sidebar,
.main-stage-view.session-pending .aside {
    /*transform: translateX(50%);
    opacity: 0;*/
    display: none;
}

.meeting-overlay.session-pending {
    display: none;
}

.session-loader {
    transform-origin: center center;
    transition: transform .3s, opacity .3s;
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.session-loader.is-visible {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
}

.session-pending-illustration {
    width: 300px;
    height: 225px;
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    max-width: 80vw;
}
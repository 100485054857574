.avatar-badge {
    position: relative;
}

.avatar-badge:after {
    opacity: 0;
    transition: all .3s;
    content: '';
    position: absolute;
    top: -4px;
    right: 2px;
    width: 12px;
    height: 12px;
    border-radius: 999px;
    background-color: var(--theme-pink);
    transform: scale(0);
    border: solid 1px white;
}

.avatar-badge.is-visible:after {
    opacity: 1;
    transform: scale(1);
}

.avatar-action {
    position: relative;
}

.avatar-action__icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 28px;
    height: 28px;
    border-radius: 99999px;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border: solid 2px white;
    transform: translateY(-12px) translateX(12px);
}
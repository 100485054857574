.activity-feed {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.activity {
    background-color: white;
    padding: 10px 16px;
    border-radius: var(--border-radius);
}

.activity-node {
    font-size: 14px;
}

.activity-type {
    opacity: 0.5;
}

.activity-tile {
    padding-top: 12px;
}

.joynt-theme .tile.general-activity {
    background-color: transparent;
    border-left: solid 3px var(--primary-light);
    opacity: 0.6;
    box-shadow: none;
}

.activity-time {
    opacity: 0.5;
    font-size: 11px;
}

.activity-time.is-new {
    opacity: 1;
    font-weight: bold;
    color: var(--success-color);
    display: inline-flex;
    align-items: center;
}

.activity-time.is-new:after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--success-color);
    margin-left: 0.4em;
}
.badge {
    padding: 4px 8px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 99px
}

.badge--dot {
    width: 8px;
    height: 8px;
    padding: 0;
    background-color: var(--success-color);
}